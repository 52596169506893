import { DebtCategory } from './DebtCategory';
import { DebtUpdate } from './DebtUpdate';

export enum MinimumPaymentCalculationType {
  FixedAmount = 'fixed_amount',
  PercentOfPrincipal = 'percent_of_principal',
  PercentOfPrincipalAndInterest = 'percent_of_principal_and_interest',
}

export enum PaymentFrequencyType {
  Monthly = 'monthly',
  DayInterval = 'day_interval',
}

export const paymentFrequencyTypeLabel = {
  [PaymentFrequencyType.Monthly]: 'Once a month',
  [PaymentFrequencyType.DayInterval]: 'Every interval of days',
};

export interface Debt {
  id: string;
  planId: string;
  name: string | null;
  category: DebtCategory | null;
  apr: number | null;
  initialBalance: number | null;
  initialNextPaymentDueDate: string | null;
  minimumPaymentCalculationType: MinimumPaymentCalculationType | null;
  minimumPaymentFixed?: number | null;
  minimumPaymentPercent?: number | null; // Only used when minimumPaymentCalculationType is not FIXED_AMOUNT
  lowestPaymentAllowed?: number | null; // Only used when minimumPaymentCalculationType is not FIXED_AMOUNT
  paymentFrequencyType: PaymentFrequencyType;
  paymentIntervalDays?: number | null; // Only used when paymentFrequencyType is DAY_INTERVAL
  promoApr?: number | null; // Promotional APR, if applicable
  promoAprEndDate?: string | null; // End date of promotional APR period in ISO format
  note?: string | null; // Optional note for the debt
  updates?: DebtUpdate[];
  createdAt: string;
  updatedAt: string | null;
}
