import { useState } from 'react';
import { InviteConfig } from '@/types';
import { getInviteConfigByClientCookie } from '@/utils';

export function useInviteConfig(): InviteConfig | null {
  const [inviteConfig, _] = useState<InviteConfig | null>(() =>
    getInviteConfigByClientCookie()
  );
  return inviteConfig;
}
