import { Debt, DebtUpdate, MinimumPaymentCalculationType } from '@/types';

export function calculateDebtMinimumPayment(
  debt: Debt,
  balance: number,
  monthlyInterestPayment = 0,
  debtUpdate?: DebtUpdate | null
): number {
  let calculatedMinimumPayment = 0;

  switch (debt.minimumPaymentCalculationType) {
    case MinimumPaymentCalculationType.FixedAmount:
      calculatedMinimumPayment = debt.minimumPaymentFixed || 0;
      break;

    case MinimumPaymentCalculationType.PercentOfPrincipal:
      const percentPayment =
        (balance * (debt.minimumPaymentPercent || 0)) / 100;
      calculatedMinimumPayment = Math.max(
        percentPayment,
        debt.lowestPaymentAllowed || 0
      );
      break;

    case MinimumPaymentCalculationType.PercentOfPrincipalAndInterest:
      const totalPayment =
        ((balance + monthlyInterestPayment) *
          (debt.minimumPaymentPercent || 0)) /
        100;
      calculatedMinimumPayment = Math.max(
        totalPayment,
        debt.lowestPaymentAllowed || 0
      );
      break;

    default:
      break;
  }

  // If the debt balance is greater than or equal to the
  // minimum payment, and if the debt update minimum payment
  // is set, use the debt update minimum payment.
  // This is to both set the accurate extra payment per plan step,
  // but also provide the snowballing effect of passing paid off
  // debt's minimum payment to the next priority debt.
  // This may be error-prone, but looks good for now. 😓
  if (
    balance >= calculatedMinimumPayment &&
    debtUpdate?.minimumPayment !== null &&
    debtUpdate?.minimumPayment !== undefined
  ) {
    return debtUpdate.minimumPayment;
  }

  return calculatedMinimumPayment;
}
