import { useState } from 'react';

export interface UseAmityStateReturn {
  isProviderNeeded: boolean;
  isProviderConnected: boolean;
  setIsProviderNeeded: (value: boolean) => void;
  setIsProviderConnected: (value: boolean) => void;
  clearData: () => void;
}

export function useAmityState(): UseAmityStateReturn {
  const [isProviderNeeded, setIsProviderNeeded] = useState(false);
  const [isProviderConnected, setIsProviderConnected] = useState(false);

  const clearData = () => {
    setIsProviderNeeded(false);
    setIsProviderConnected(false);
  };

  return {
    isProviderNeeded,
    isProviderConnected,
    setIsProviderNeeded,
    setIsProviderConnected,
    clearData,
  };
}
