import { useMemo } from 'react';
import { DebtPaymentStatus, Plan } from '@/types';
import { usePlanSchedule } from './usePlanSchedule';
import { useDefaultPlan } from './useDefaultPlan';

export function usePlanReadyCount(plan?: Plan | null): number {
  const { plan: defaultPlan } = useDefaultPlan();
  const planSchedule = usePlanSchedule(plan ?? defaultPlan);
  return useMemo(
    () =>
      planSchedule.filter(
        (item) => item.debtPaymentStatus === DebtPaymentStatus.Ready
      ).length,
    [planSchedule]
  );
}
