'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { useCurrentUrl } from './useCurrentUrl';

export interface UseInstantNavigationReturn {
  currentUrl: string;
  currentPathname: string;
  pendingUrl: string | null;
  push: (pathname: string) => void;
  isLoading: boolean;
}

const InstantNavigationContext = createContext<UseInstantNavigationReturn>({
  currentUrl: '',
  currentPathname: '',
  pendingUrl: null,
  push: () => {},
  isLoading: false,
});

export const useInstantNavigation = (): UseInstantNavigationReturn => {
  return useContext(InstantNavigationContext);
};

function getPathnameFromUrl(url: string) {
  return url.split('?')[0] ?? '';
}

const MAX_NAVIGATION_TIMEOUT = 10000;

export function InstantNavigationProvider({
  children,
}: {
  readonly children?: React.ReactNode;
}): JSX.Element {
  const { currentUrl: realCurrentUrl } = useCurrentUrl();
  const [pendingUrl, setPendingUrl] = useState<string | null>(null);
  const [currentUrl, setCurrentUrl] = useState<string>(realCurrentUrl);
  const [currentPathname, setCurrentPathname] = useState<string>(
    getPathnameFromUrl(realCurrentUrl)
  );

  useEffect(() => {
    if (pendingUrl && pendingUrl !== realCurrentUrl) {
      setCurrentUrl(pendingUrl);

      // Reset if navigation takes too long
      const timeoutId = setTimeout(() => {
        if (pendingUrl) {
          setPendingUrl(null);
          setCurrentUrl(realCurrentUrl);
        }
      }, MAX_NAVIGATION_TIMEOUT);

      return () => clearTimeout(timeoutId);
    }
    setPendingUrl(null);
    setCurrentUrl(realCurrentUrl);
  }, [realCurrentUrl, pendingUrl]);

  useEffect(() => {
    setCurrentPathname(getPathnameFromUrl(currentUrl));
  }, [currentUrl]);

  const push = (pathname: string) => {
    window.scrollTo(0, 0);
    setPendingUrl(pathname);
  };

  return (
    <InstantNavigationContext.Provider
      value={{
        currentUrl,
        currentPathname,
        pendingUrl,
        push,
        isLoading: pendingUrl !== null,
      }}
    >
      {children}
    </InstantNavigationContext.Provider>
  );
}
