import { Debt, DebtPayoffMethod } from '@/types';
import { getDebtInitialBalance } from './getDebtInitialBalance';

export function sortDebts(
  debts: Debt[],
  method?: DebtPayoffMethod | null,
  debtPayoffCustomOrder?: string[] | null
): Debt[] {
  const sortedDebts = [...debts];
  if (!method || method === DebtPayoffMethod.Avalanche) {
    return sortedDebts.sort((a, b) => {
      // If either APR is null, move it to the bottom
      if (a.apr === null && b.apr === null) return 0;
      if (a.apr === null) return 1;
      if (b.apr === null) return -1;

      // Otherwise sort by APR in descending order
      return b.apr - a.apr;
    });
  } else if (method === DebtPayoffMethod.Snowball) {
    return sortedDebts.sort((a, b) => {
      // Get balances using the getDebtInitialBalance utility
      const aBalance = getDebtInitialBalance(a);
      const bBalance = getDebtInitialBalance(b);

      // If either balance is 0, move it to the bottom
      if (aBalance === 0 && bBalance === 0) return 0;
      if (aBalance === 0) return 1;
      if (bBalance === 0) return -1;

      // Otherwise sort by balance in ascending order
      return aBalance - bBalance;
    });
  } else if (method === DebtPayoffMethod.Custom) {
    return sortDebts(sortedDebts).sort((a, b) => {
      const aIndex = debtPayoffCustomOrder?.indexOf(a.id);
      const bIndex = debtPayoffCustomOrder?.indexOf(b.id);
      return (aIndex ?? 0) - (bIndex ?? 0);
    });
  }
  return sortedDebts;
}
