import posthog from 'posthog-js';
import Gleap from 'gleap';
import { Profile } from '@/types';

export function identifyUserProfileInExternalServices(profile: Profile): void {
  posthog.identify(profile.id, {
    defaultPlanId: profile.defaultPlanId,
    debtFreeDate: profile.debtFreeDate,
  });
  if (Gleap.isUserIdentified()) {
    Gleap.updateContact({
      customData: {
        defaultPlanId: profile.defaultPlanId,
        debtFreeDate: profile.debtFreeDate,
      },
    });
  }
}
