import posthog from 'posthog-js';
import Gleap from 'gleap';
import { User } from '@supabase/supabase-js';

export function identifyUserInExternalServices(user: User): void {
  posthog.identify(user.id, {
    email: user.email,
  });
  Gleap.identify(user.id, {
    email: user.email,
    createdAt: new Date(user.created_at),
  });
}
