export enum PlanScheduleItemType {
  DebtPayment = 'debt_payment',
  AddToDebtPaymentBudget = 'add_to_debt_payment_budget',
  ChargeInterest = 'charge_interest',
  DebtPayoff = 'debt_payoff',
  DebtFree = 'debt_free',
}

export const planScheduleItemTypeLabels = {
  [PlanScheduleItemType.DebtPayment]: 'Debt Payment',
  [PlanScheduleItemType.AddToDebtPaymentBudget]: 'Monthly Budget',
  [PlanScheduleItemType.ChargeInterest]: 'Charge Interest',
  [PlanScheduleItemType.DebtPayoff]: 'Debt Payoff',
  [PlanScheduleItemType.DebtFree]: 'Debt Free',
};
