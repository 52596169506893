import { Debt, DebtSnakeCase } from '@/types';

export function convertToDebtSnakeCase(debt: Debt): DebtSnakeCase {
  return {
    id: debt.id,
    plan_id: debt.planId,
    name: debt.name,
    category: debt.category,
    apr: debt.apr,
    initial_balance: debt.initialBalance,
    initial_next_payment_due_date: debt.initialNextPaymentDueDate,
    minimum_payment_calculation_type: debt.minimumPaymentCalculationType,
    minimum_payment_fixed: debt.minimumPaymentFixed,
    minimum_payment_percent: debt.minimumPaymentPercent,
    lowest_payment_allowed: debt.lowestPaymentAllowed,
    payment_frequency_type: debt.paymentFrequencyType,
    payment_interval_days: debt.paymentIntervalDays,
    promo_apr: debt.promoApr,
    promo_apr_end_date: debt.promoAprEndDate,
    note: debt.note,
    created_at: debt.createdAt,
    updated_at: debt.updatedAt,
  };
}
