import { useMemo } from 'react';
import { Plan, PlanSchedule } from '@/types';
import { generatePlanSchedule } from '@/utils';

export function usePlanSchedule(plan?: Plan | null): PlanSchedule {
  return useMemo(
    () => generatePlanSchedule(plan),
    [plan?.debts, plan?.debtPayoffMethod, plan?.plannedExtraDebtPayments]
  );
}
