import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { Subscription, User } from '@supabase/supabase-js';
import { createSupabaseClient } from '@/utils/supabase/client';
import posthog from 'posthog-js';
import Gleap from 'gleap';
import * as actions from '@/actions';
import { useAnonymousUserStore } from '@/stores';
import { identifyUserInExternalServices } from '@/utils';

interface UseUserProps {
  fetchOnMount?: boolean;
}

export interface UseUserReturn {
  data: User | null;
  error: string | null;
  isReady: boolean;
  isFetching: boolean | null;
  isLoggingOut: boolean;
  fetchData: () => Promise<{ data?: User | null; error?: string | null }>;
  logout: (
    redirectUrl?: string | null
  ) => Promise<{ error?: string | null } | void>;
}

export function useUser({ fetchOnMount }: UseUserProps = {}): UseUserReturn {
  const router = useRouter();
  const [data, setData] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { reset: resetAnonymousUserStore } = useAnonymousUserStore();

  const fetchData = async (): Promise<{
    data?: User | null;
    error?: string;
  }> => {
    setIsFetching(true);
    try {
      const supabase = createSupabaseClient();
      const res = await supabase.auth.getUser();
      setData(res.data.user);
      setupSubscription();
      if (res.data.user) {
        if (!data) {
          identifyUserInExternalServices(res.data.user);
        }
        resetAnonymousUserStore();
      }
      return { data: res.data.user };
    } catch (e) {
      const errorMessage = `Failed to load user: ${(e as Error)?.message}`;
      console.error('Failed to load user:', e);
      setError(errorMessage);
      setData(null);
      return { error: errorMessage };
    } finally {
      setIsReady(true);
      setIsFetching(false);
    }
  };

  const setupSubscription = () => {
    // Set up realtime subscription for auth state changes
    if (subscription) return;
    const supabase = createSupabaseClient();
    const {
      data: { subscription: newSubscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session?.user) {
        setData(session?.user);
      } else {
        clearData();
      }
    });
    setSubscription(newSubscription);
  };

  const clearSubscription = () => {
    subscription?.unsubscribe();
    setSubscription(null);
  };

  useEffect(() => {
    if (fetchOnMount !== false) fetchData();
    return clearSubscription;
  }, []);

  const logout = async (
    redirectUrl: string | null = '/'
  ): Promise<{ error?: string | null } | void> => {
    setIsLoggingOut(true);
    try {
      const response = await actions.logout();
      if (response?.error) {
        setIsLoggingOut(false);
        return { error: `Failed to log out: ${response?.error}` };
      }
      setTimeout(() => setIsLoggingOut(false), 3000);
      clearData();
      posthog.reset();
      Gleap.clearIdentity();
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    } catch (e) {
      console.error('Failed to log out:', e);
      return { error: `Failed to log out: ${(e as Error)?.message}` };
    } finally {
      setIsLoggingOut(false);
    }
  };

  const clearData = () => {
    setData(null);
    setError(null);
    clearSubscription();
  };

  return {
    data,
    error,
    isReady,
    isFetching,
    isLoggingOut,
    fetchData,
    logout,
  };
}
