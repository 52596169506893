import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ColorScheme } from '../types';

const getInitialColorScheme = (): ColorScheme => {
  return 'light';

  // if (typeof window === 'undefined') return 'light';
  // try {
  //   const stored = localStorage.getItem('preferences-storage');
  //   if (stored) {
  //     const { state } = JSON.parse(stored);
  //     if (state?.colorScheme) return state.colorScheme;
  //   }
  //   return window.matchMedia('(prefers-color-scheme: dark)').matches
  //     ? 'dark'
  //     : 'light';
  // } catch {
  //   return 'light';
  // }
};

interface PreferencesStore {
  defaultPlanId: string | null;
  colorScheme: ColorScheme;
  setDefaultPlanId: (planId: string | null) => void;
  toggleColorScheme: () => void;
}

export const usePreferencesStore = create<PreferencesStore>()(
  persist(
    (set) => ({
      defaultPlanId: null,
      colorScheme: getInitialColorScheme(),
      setDefaultPlanId: (planId) => set({ defaultPlanId: planId }),
      toggleColorScheme: () =>
        set((state) => ({
          // colorScheme: state.colorScheme === 'light' ? 'dark' : 'light',
          colorScheme: 'light',
        })),
    }),
    {
      name: 'finex/preferences',
    }
  )
);
