export enum DebtCategory {
  CreditCard = 'credit_card',
  AutoLoan = 'auto_loan',
  PersonalLoan = 'personal_loan',
  StudentDebt = 'student_debt',
  Mortgage = 'mortgage',
  MedicalDebt = 'medical_debt',
  Taxes = 'taxes',
  Other = 'other',
}

export const debtCategories = Object.values(DebtCategory);

export const debtCategoryLabels: Record<DebtCategory, string> = {
  [DebtCategory.CreditCard]: 'Credit Card',
  [DebtCategory.AutoLoan]: 'Auto Loan',
  [DebtCategory.PersonalLoan]: 'Personal Loan',
  [DebtCategory.StudentDebt]: 'Student Debt',
  [DebtCategory.Mortgage]: 'Mortgage',
  [DebtCategory.MedicalDebt]: 'Medical Debt',
  [DebtCategory.Taxes]: 'Taxes',
  [DebtCategory.Other]: 'Other',
};
