import { useCallback, useEffect } from 'react';
import Gleap from 'gleap';
import debounce from 'lodash.debounce';
import { UseProfileReturn, UseUserReturn } from '@/hooks';

export function useUpdateGleapUser({
  user,
  profile,
  amityUser,
}: {
  readonly user: UseUserReturn;
  readonly profile: UseProfileReturn;
  readonly amityUser: Amity.User | null;
}): void {
  const updateGleapUserAsNeeded = async (
    inputUser: UseUserReturn,
    inputProfile: UseProfileReturn,
    inputAmityUser: Amity.User | null
  ) => {
    console.log('updateGleapUserAsNeeded()...');
    if (!inputUser.data || !inputProfile.data || !inputAmityUser) {
      return;
    }

    const gleapUser = Gleap.getIdentity();

    // Email
    const email = inputUser.data.email;
    const gleapEmail = gleapUser.email as string | undefined;
    if (email && gleapEmail !== email) {
      console.log('⏳ updateGleapUserAsNeeded(), setting email:', email);
      Gleap.updateContact({
        email,
      });
    } else {
      console.log('✅ updateGleapUserAsNeeded(), email already set:', email);
    }

    // Name
    const userId = inputUser.data.id;
    const amityDisplayName = inputAmityUser.displayName;
    const gleapName = gleapUser.name as string | undefined;
    if (
      amityDisplayName &&
      amityDisplayName !== userId &&
      amityDisplayName !== gleapName
    ) {
      console.log(
        '⏳ updateGleapUserAsNeeded(), setting name:',
        amityDisplayName
      );
      Gleap.updateContact({
        name: amityDisplayName,
      });
    } else {
      console.log(
        '✅ updateGleapUserAsNeeded(), name already set:',
        amityDisplayName
      );
    }
  };

  const debouncedUpdateGleapUserAsNeeded = useCallback(
    debounce(updateGleapUserAsNeeded, 3000),
    []
  );

  useEffect(() => {
    debouncedUpdateGleapUserAsNeeded(user, profile, amityUser);

    return () => {
      debouncedUpdateGleapUserAsNeeded.cancel();
    };
  }, [user, profile, amityUser]);
}
