import { env } from './env';

export function isDevMode(): boolean {
  const devModeLocalStorage = localStorage.getItem('finex-dev-mode');
  return (
    env.NEXT_PUBLIC_DEV_MODE ||
    devModeLocalStorage === '1' ||
    devModeLocalStorage === 'true'
  );
}
