import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

function getCurentUrl(): string {
  const pathname = window.location?.pathname;
  const search = window.location?.search;
  return `${pathname}${search ? `${search}` : ''}`;
}

export function useCurrentUrl() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [currentUrl, setCurrentUrl] = useState<string>(getCurentUrl());

  useEffect(() => {
    setCurrentUrl(getCurentUrl());
  }, [pathname, searchParams]);

  return { currentUrl, pathname, searchParams };
}
