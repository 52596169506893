import { useEffect, useState } from 'react';
import { StripeState } from '@/types';
import { getStripeData } from '@/actions';

export interface UseStripeStateReturn {
  data: StripeState | null;
  error: string | null;
  isReady: boolean;
  isFetching: boolean | null;
  isLoading: boolean;
  isSubscriptionActive: boolean | null;
  fetchData: () => Promise<{
    data?: StripeState | null;
    error?: string | null;
  }>;
  clearData: () => void;
}

export function useStripeState(): UseStripeStateReturn {
  const [data, setData] = useState<StripeState | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const isLoading = isFetching;
  const isSubscriptionActive =
    data?.subscription?.status === 'active' ||
    data?.subscription?.status === 'trialing';

  const fetchData = async (): Promise<{
    data?: StripeState | null;
    error?: string | null;
  }> => {
    setIsFetching(true);
    try {
      const stripeState = await getStripeData();
      setData(stripeState);
      return { data: stripeState };
    } catch (e) {
      console.error('Error fetching stripeState:', e);
      const errorMessage = `Failed to load stripeState: ${(e as Error)?.message}`;
      setError(errorMessage);
      return { error: errorMessage };
    } finally {
      setIsReady(true);
      setIsFetching(false);
    }
  };

  const clearData = () => {
    setData(null);
    setError(null);
    setIsReady(false);
  };

  return {
    data,
    error,
    isReady,
    isFetching,
    isLoading,
    isSubscriptionActive,
    fetchData,
    clearData,
  };
}
