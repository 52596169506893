'use client';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { isDateWithinMins } from '@/utils';
import { usePlans, UsePlansReturn } from './usePlans';
import { useUser, UseUserReturn } from './useUser';
import { useProfile, UseProfileReturn } from './useProfile';
import { useSyncProfileDebtFreeDate } from './useSyncProfileDebtFreeDate';
import { useStripeState, UseStripeStateReturn } from './useStripeState';
import { useAmityState, UseAmityStateReturn } from './useAmityState';
import {
  hasSubscriptionButNotActive,
  isSubscriptionActive,
} from '@/utils/stripe';

enum Routes {
  Membership = '/membership',
  MembershipInactive = '/membership/inactive',
  MembershipCheckout = '/membership/checkout',
}

export interface UseFinexReturn {
  user: UseUserReturn;
  profile: UseProfileReturn;
  plans: UsePlansReturn;
  stripeState: UseStripeStateReturn;
  amityState: UseAmityStateReturn;
}

const FinexContext = createContext<UseFinexReturn>({
  user: {
    data: null,
    error: null,
    isReady: false,
    isFetching: false,
    isLoggingOut: false,
    fetchData: async () => Promise.resolve({ data: null, error: null }),
    logout: async () => Promise.resolve({ error: null }),
  },
  profile: {
    data: null,
    error: null,
    isReady: false,
    isFetching: false,
    isUpdating: false,
    isLoading: false,
    fetchData: async () => Promise.resolve({ data: null, error: null }),
    updateData: async () => Promise.resolve({ success: false, error: null }),
    clearData: () => {},
  },
  plans: {
    data: [],
    error: null,
    isReady: false,
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isLoading: false,
    currentPlan: null,
    currentDebt: null,
    fetchPlans: async () => Promise.resolve({ data: [], error: null }),
    createPlan: async () => Promise.resolve({ data: undefined, error: null }),
    updatePlan: async () => Promise.resolve({ success: false, error: null }),
    deletePlan: async () => Promise.resolve({ success: false, error: null }),
    createDebt: async () => Promise.resolve({ data: undefined, error: null }),
    updateDebt: async () => Promise.resolve({ success: false, error: null }),
    deleteDebt: async () => Promise.resolve({ success: false, error: null }),
    createPlannedExtraDebtPayment: async () =>
      Promise.resolve({ data: undefined, error: null }),
    updatePlannedExtraDebtPayment: async () =>
      Promise.resolve({ success: false, error: null }),
    deletePlannedExtraDebtPayment: async () =>
      Promise.resolve({ success: false, error: null }),
    createDebtUpdate: async () =>
      Promise.resolve({ data: undefined, error: null }),
    updateDebtUpdate: async () =>
      Promise.resolve({ success: false, error: null }),
    deleteDebtUpdate: async () =>
      Promise.resolve({ success: false, error: null }),
    clearData: () => {},
  },
  stripeState: {
    data: null,
    error: null,
    isReady: false,
    isFetching: false,
    isLoading: false,
    isSubscriptionActive: null,
    fetchData: async () => Promise.resolve({ data: null, error: null }),
    clearData: () => {},
  },
  amityState: {
    isProviderNeeded: false,
    isProviderConnected: false,
    setIsProviderNeeded: () => {},
    setIsProviderConnected: () => {},
    clearData: () => {},
  },
});

export const useFinex = (): UseFinexReturn => {
  return useContext(FinexContext);
};

export function FinexProvider({
  children,
}: {
  readonly children?: React.ReactNode;
}): JSX.Element {
  const router = useRouter();
  const pathname = usePathname();

  const user = useUser({
    fetchOnMount: false,
  });

  const profile = useProfile({
    fetchOnMount: false,
  });

  const plans = usePlans({
    fetchOnMount: false,
  });

  const stripeState = useStripeState();

  const amityState = useAmityState();

  useEffect(() => {
    user.fetchData();
  }, [pathname]);

  useEffect(() => {
    if (user.data) {
      if (!profile.data && !profile.isFetching) {
        profile.fetchData();
      }
      if (!plans.data.length && !plans.isFetching) {
        plans.fetchPlans();
      }
      if (!stripeState.isFetching) {
        stripeState.fetchData();
      }
    } else {
      profile.clearData();
      plans.clearData();
      stripeState.clearData();
      amityState.clearData();
    }
  }, [user.data]);

  useEffect(() => {
    if (
      pathname.startsWith(Routes.Membership) ||
      !user?.data ||
      !stripeState?.isReady
    ) {
      return;
    }
    if (!stripeState.data?.subscription) {
      if (isDateWithinMins(user.data.created_at, 5)) {
        router.push(Routes.MembershipCheckout);
        return;
      } else {
        router.push(Routes.MembershipInactive);
        return;
      }
    } else if (hasSubscriptionButNotActive(stripeState.data.subscription)) {
      router.push(Routes.MembershipInactive);
      return;
    } else if (isSubscriptionActive(stripeState.data.subscription)) {
      amityState.setIsProviderNeeded(true);
    }
  }, [pathname, user?.data, stripeState]);

  useSyncProfileDebtFreeDate({ profile, plans });

  const value = useMemo(
    () => ({ user, profile, plans, stripeState, amityState }),
    [user, profile, plans, stripeState, amityState]
  );

  return (
    <FinexContext.Provider value={value}>{children}</FinexContext.Provider>
  );
}
