import { InviteConfig } from '@/types';

export const inviteConfigs: InviteConfig[] = [
  {
    id: 'noahamar',
    slug: 'noah',
    name: 'Noah',
    communityName: 'Debt Warriors',
    communityImageUrl:
      '/assets/images/community-banners/money-freedom-banner.jpg',
    rewardfulToken: 'noah',
    amityCommunityId: '67bba653c93ecc5118cc889d',
  },
  {
    id: 'denye',
    slug: 'denye',
    name: "De'Nye",
    communityName: "It's Sexy When You're Debt&#8209;Free",
    communityImageUrl: '/assets/images/community-banners/denye-banner.jpg',
    rewardfulToken: 'denye',
    amityCommunityId: '67d1ddd9eacf8b9cfbab8dd0',
  },
];
