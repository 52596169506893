export function getTimesOfDayName() {
  const hour = new Date().getHours();
  let timeOfDayName = 'morning';
  if (hour >= 18 || hour < 4) {
    timeOfDayName = 'evening';
  } else if (hour >= 12) {
    timeOfDayName = 'afternoon';
  }
  return timeOfDayName;
}
