import { startOfDay } from 'date-fns';
import { Plan } from '@/types';

// Helper function to find the oldest debt update date across all debts
export const findOldestDebtUpdateDate = (plan?: Plan | null): Date => {
  if (!plan?.debts) return startOfDay(new Date());

  let oldestDate = startOfDay(new Date());

  plan.debts.forEach((debt) => {
    if (!debt.updates || debt.updates.length === 0) return;

    debt.updates.forEach((update) => {
      if (!update.startDate) return;

      const updateDate = new Date(update.startDate);
      if (updateDate < oldestDate) {
        oldestDate = startOfDay(updateDate);
      }
    });
  });

  return oldestDate;
};
