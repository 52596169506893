import { Plan, PlanSchedule, PayoffStats } from '@/types';
import { useFinex, usePayoffStats, usePlanSchedule } from '@/hooks';

export function useDefaultPlan(): {
  plan: Plan | null;
  planSchedule: PlanSchedule;
  stats: PayoffStats;
} {
  const { profile, plans } = useFinex();
  const plan =
    plans.data.find((plan) => plan.id === profile.data?.defaultPlanId) ?? null;
  const planSchedule = usePlanSchedule(plan);
  const stats = usePayoffStats(planSchedule);
  return {
    plan,
    planSchedule,
    stats,
  };
}
