// const currencyFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'USD',
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// });

// export function formatCurrency(amount?: number | null): string {
//   if (amount === undefined || amount === null) return '';
//   return currencyFormatter.format(amount);
// }

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencyFormatterWithoutCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const formatCurrency = (
  num?: number | null,
  includeCents = true
): string => {
  if (num === undefined || num === null) return '';
  if (includeCents) {
    let value = currencyFormatter.format(num);
    if (value.endsWith('.00')) {
      value = currencyFormatterWithoutCents.format(num);
    }
    return value;
  }
  return currencyFormatterWithoutCents.format(num);
};
