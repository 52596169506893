import { useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { parseISO } from 'date-fns';
import { UseProfileReturn } from './useProfile';
import { UsePlansReturn } from './usePlans';
import { usePlanSchedule } from './usePlanSchedule';
import { usePayoffStats } from './usePayoffStats';

interface UseSyncProfileDebtFreeDateProps {
  plans: UsePlansReturn;
  profile: UseProfileReturn;
}

export function useSyncProfileDebtFreeDate({
  plans,
  profile,
}: UseSyncProfileDebtFreeDateProps): void {
  const defaultPlan =
    plans.data.find((plan) => plan.id === profile.data?.defaultPlanId) ?? null;

  const planSchedule = usePlanSchedule(defaultPlan);
  const stats = usePayoffStats(planSchedule);

  const profileRef = useRef(profile);
  profileRef.current = profile;

  const plansRef = useRef(plans);
  plansRef.current = plans;

  const statsRef = useRef(stats);
  statsRef.current = stats;

  const debouncedUpdate = useCallback(
    debounce(
      async (userId: string, debtFreeDate: string | null) => {
        // console.log('Calling profile.updateData():', {
        //   userId,
        //   debtFreeDate,
        // });
        await profileRef.current.updateData(userId, {
          debtFreeDate,
        });
      },
      3000,
      { leading: true, trailing: true } // Execute on the leading and trailing edge
    ),
    []
  );

  useEffect(() => {
    // Only run when profile and plans are ready and not currently updating
    const userId = profile.data?.id;
    if (!profile.isReady || profile.isUpdating || !plans.isReady || !userId) {
      // console.log('Condition 1: Not ready, exiting');
      return;
    }

    const currentDate = profile.data?.debtFreeDate
      ? parseISO(profile.data?.debtFreeDate).toISOString()
      : null;
    const newDate = stats.payoffDate?.toISOString() ?? null;

    // Skip if dates are the same (both null or equal strings)
    if (currentDate === newDate) {
      // console.log('Condition 2: The same, exiting');
      return;
    }

    // If we have no payoff date but there's a current date, clear it
    if (!newDate && currentDate) {
      // console.log('Condition 3: No new date, clearing current');
      debouncedUpdate(userId, null);
      return;
    }

    // If we have a new payoff date that's different from the current one
    if (newDate && currentDate !== newDate) {
      // console.log('Condition 4: New date, updating');
      debouncedUpdate(userId, newDate);
    }

    // console.log('No condition, exiting');
  }, [profile, plans, stats]);
}
