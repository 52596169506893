export enum PlannedExtraDebtPaymentFrequencyType {
  Monthly = 'monthly',
  DayInterval = 'day_interval',
  OneTime = 'one_time',
}

export const plannedExtraDebtPaymentFrequencyTypeLabel = {
  [PlannedExtraDebtPaymentFrequencyType.Monthly]: 'Monthly',
  [PlannedExtraDebtPaymentFrequencyType.DayInterval]: 'Every interval of days',
  [PlannedExtraDebtPaymentFrequencyType.OneTime]: 'One-time',
};
