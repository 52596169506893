import { isSameDay } from 'date-fns';
import {
  NextDebtUpdate,
  Plan,
  PlanScheduleItem,
  PlanScheduleItemType,
} from '@/types';
import { getSortedDebtUpdates } from './getSortedDebtUpdates';
import { getNextDebtUpdate } from './getNextDebtUpdate';

export function getCanCreateNextDebtUpdate(
  plan: Plan,
  item: PlanScheduleItem
): {
  canCreateNextDebtUpdate: boolean;
  nextDebtUpdate: NextDebtUpdate | undefined;
} {
  let canCreateNextDebtUpdate = false;
  let nextDebtUpdate: NextDebtUpdate | undefined;

  if (item.type === PlanScheduleItemType.DebtPayment && item.debtId) {
    const debt = plan.debts.find((d) => d.id === item.debtId);
    const sortedDebtUpdates = getSortedDebtUpdates(debt?.updates);
    nextDebtUpdate = getNextDebtUpdate(debt, sortedDebtUpdates[0]);

    if (nextDebtUpdate && item) {
      const date1 = nextDebtUpdate.endDate;
      const date2 = item.date;
      if (date1 && date2 && isSameDay(date1, date2)) {
        canCreateNextDebtUpdate = true;
      }
    }
  }

  return { canCreateNextDebtUpdate, nextDebtUpdate };
}
