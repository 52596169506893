import { Debt } from '@/types';

export function getDebtInitialBalance(debt: Debt) {
  const earliestUpdate =
    debt.updates && debt.updates.length > 0
      ? debt.updates.reduce((earliest, current) => {
          const earliestDate = new Date(
            earliest.startDate || earliest.endDate || new Date(0)
          );
          const currentDate = new Date(
            current.startDate || current.endDate || new Date(0)
          );
          return currentDate < earliestDate ? current : earliest;
        })
      : null;

  return earliestUpdate?.balance ?? debt.initialBalance ?? 0;
}
