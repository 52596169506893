import { startOfDay, startOfMonth } from 'date-fns';
import {
  Debt,
  DebtPayoffMethod,
  DebtUpdate,
  Plan,
  PlanSchedule,
  PlanScheduleItem,
  PlanScheduleItemType,
  PlannedExtraDebtPaymentFrequencyType,
} from '@/types';
import { calculateDebtMinimumPayment } from '../calculateDebtMinimumPayment';
import { calculateMonthlyInterest } from '../calculateMonthlyInterest';
import { findLatestDebtUpdate } from '../findLatestDebtUpdate';
import { findOldestDebtUpdateDate } from '../findOldestDebtUpdateDate';
import { getEffectiveApr } from '../getEffectiveApr';
import { round } from '../round';
import { getDebtInitialBalance } from '../getDebtInitialBalance';
import { getDebtPaymentStatus, getMonthlyMinimumPayments } from './utils';

const MAX_YEARS = 50;
const MAX_DAYS = MAX_YEARS * 365.25;

/**
 * Generates a payment schedule for a debt repayment plan.
 *
 * IMPORTANT IMPLEMENTATION NOTES:
 *
 * Balance Calculation Logic:
 * When using debt updates from historical data, the entire payment amount (including both principal
 * and interest) is deducted from the balance, rather than just the principal portion. This is done
 * intentionally to reflect real-world debt payment scenarios where the balance after a payment
 * represents the total amount still owed after the payment is made.
 *
 * This approach means that when a payment is made:
 * 1. The interest portion is effectively "paid off" and doesn't get added to the balance
 * 2. The balance is reduced by the full payment amount
 * 3. The principal reduction is calculated as (payment - interest) for reporting purposes
 *
 * This matches how financial institutions typically report balances after payments, where the
 * interest is charged but then immediately paid as part of the payment, leaving only the
 * principal reduction to affect the balance.
 *
 * @param plan The plan to generate the schedule for
 * @returns The generated payment schedule
 */
export const generatePlanSchedule = (plan?: Plan | null): PlanSchedule => {
  const debtBalances = new Map(
    plan?.debts.map((d) => [d.id, getDebtInitialBalance(d)])
  );

  // Track latest debt updates used for each debt
  const lastUsedDebtUpdates = new Map<string, DebtUpdate>();

  // Helper function to get payment date for a debt in a given period
  const getPaymentDateForPeriod = (debt: Debt, periodDate: Date): Date => {
    // Find all updates that end in this month or earlier
    const periodStart = new Date(
      periodDate.getFullYear(),
      periodDate.getMonth(),
      1
    );
    const relevantUpdates = debt.updates
      ?.filter((update) => update.endDate)
      ?.sort(
        (a, b) =>
          new Date(b.endDate!).getTime() - new Date(a.endDate!).getTime()
      );

    if (!relevantUpdates?.length) {
      // Default to the 1st of the month if no updates found
      const defaultDate = new Date(periodDate);
      defaultDate.setDate(1);
      return defaultDate;
    }

    // Find the update that determines the payment date for this period
    const updateForPeriod = relevantUpdates.find((update) => {
      const updateDate = new Date(update.endDate!);
      // The update must be from this month or earlier
      return (
        updateDate <=
        new Date(periodDate.getFullYear(), periodDate.getMonth() + 1, 0)
      );
    });

    if (updateForPeriod?.endDate) {
      const updateDate = new Date(updateForPeriod.endDate);
      const paymentDate = new Date(periodDate);
      paymentDate.setDate(updateDate.getDate());
      return paymentDate;
    }

    // Default to the 1st of the month if no relevant update found
    const defaultDate = new Date(periodDate);
    defaultDate.setDate(1);
    return defaultDate;
  };

  // Track the last used debt update for each debt to determine payment dates
  const lastKnownDebtUpdateDates = new Map<string, Date>();

  // First pass: Find the last known debt update date for each debt
  for (const debt of plan?.debts || []) {
    for (const debtUpdate of debt.updates || []) {
      if (!debtUpdate.endDate) continue;
      const currentDate = new Date(debtUpdate.endDate);
      const existingDate = lastKnownDebtUpdateDates.get(debtUpdate.debtId);

      if (!existingDate || currentDate > existingDate) {
        lastKnownDebtUpdateDates.set(debtUpdate.debtId, currentDate);
      }
    }
  }

  // Start from the oldest debt update date
  let currentDate = findOldestDebtUpdateDate(plan);

  // Track remaining budget for the current month
  let currentMonthBudget = 0; // Start at 0, will be set to monthlyBudget when first month starts
  let currentMonth = -1;

  // Track which debts are paid off
  const paidOffDebts = new Set<string>();

  // Map to store payments by date
  const datePayments = new Map<string, Map<string, PlanScheduleItem>>();

  // Track accumulated one-time payments
  let accumulatedOneTimePayments = 0;

  // Process each date in chronological order
  const processDate = (date: Date) => {
    if (!plan) return;

    // Get active debts for this date
    const activeDebts = plan.debts
      .filter((d) => {
        // Skip debts that haven't started yet
        if (d.updates && d.updates.length > 0) {
          const earliestUpdate = [...d.updates].sort((a, b) => {
            const dateA = a.startDate ? new Date(a.startDate) : new Date(0);
            const dateB = b.startDate ? new Date(b.startDate) : new Date(0);
            return dateA.getTime() - dateB.getTime();
          })[0];

          if (earliestUpdate?.startDate) {
            const startDate = new Date(earliestUpdate.startDate);
            if (startDate > date) return false;
          }
        }

        return true;
      })
      .map((debt) => {
        // Check for debt update
        const debtUpdate = findLatestDebtUpdate(debt, date);
        const balance = debtUpdate?.balance ?? debtBalances.get(debt.id) ?? 0;
        const isPaidOff = paidOffDebts.has(debt.id);

        return {
          debt,
          balance,
          debtUpdate,
          isPaidOff,
        };
      })
      .sort((a, b) => {
        if (plan.debtPayoffMethod === DebtPayoffMethod.Avalanche) {
          // Sort by APR descending, then balance ascending for ties
          const aprDiff = (b.debt.apr || 0) - (a.debt.apr || 0);
          return aprDiff !== 0 ? aprDiff : a.balance - b.balance;
        } else {
          // Snowball: Sort by balance ascending
          return a.balance - b.balance;
        }
      });

    // Skip if no active debts
    if (activeDebts.length === 0) return;

    // Get the highest priority debt ID
    const priorityDebtId = activeDebts[0]?.debt.id;

    // Calculate total minimum payments due this month
    let totalMinimumsDue = 0;
    for (const { debt } of activeDebts) {
      const paymentDate = getPaymentDateForPeriod(debt, date);
      if (paymentDate.getMonth() === date.getMonth()) {
        // Get minimum payment from debt update or calculate it
        const debtUpdate = debt.updates?.find(
          (update) =>
            update.endDate &&
            new Date(update.endDate).getMonth() === date.getMonth()
        );
        if (debtUpdate) {
          totalMinimumsDue += Math.max(0, debtUpdate.minimumPayment || 0);
        } else {
          const currentBalance = debtBalances.get(debt.id) || 0;
          const monthlyInterest = calculateMonthlyInterest(
            currentBalance,
            getEffectiveApr(debt, date)
          );
          totalMinimumsDue += calculateDebtMinimumPayment(
            debt,
            currentBalance,
            monthlyInterest,
            debtUpdate
          );
        }
      }
    }

    // Calculate the top-line monthly minimum payments.
    const monthlyMinimumPayments = getMonthlyMinimumPayments(
      date,
      activeDebts,
      debtBalances,
      false
    );

    // Calculate active monthly minimum payments.
    // This is used to calculate the freed up minimum payments.
    const activeMinimumPayments = getMonthlyMinimumPayments(
      date,
      activeDebts,
      debtBalances,
      true
    );

    // Get monthly planned extra payment
    const monthlyPlannedExtra =
      plan.plannedExtraDebtPayments
        ?.filter(
          (p) =>
            p.frequencyType === PlannedExtraDebtPaymentFrequencyType.Monthly &&
            p.startDate &&
            startOfDay(startOfMonth(p.startDate)) <= startOfDay(date) &&
            (!p.endDate ||
              startOfDay(startOfMonth(p.endDate)) >= startOfDay(date))
        )
        .reduce((sum, payment) => sum + (payment.amount || 0), 0) ?? 0;

    // Get one-time payments for this specific date and add to accumulated total
    const oneTimePayments =
      plan.plannedExtraDebtPayments?.filter(
        (p) =>
          p.frequencyType === PlannedExtraDebtPaymentFrequencyType.OneTime &&
          p.startDate &&
          new Date(p.startDate).toLocaleDateString() ===
            date.toLocaleDateString()
      ) ?? [];

    const oneTimePlannedExtra = oneTimePayments.reduce(
      (sum, payment) => sum + (payment.amount || 0),
      0
    );

    if (oneTimePlannedExtra > 0) {
      accumulatedOneTimePayments += oneTimePlannedExtra;
    }

    // Only create budget items and process payments on the 1st of each month
    const isMonthlyDate = date.getDate() === 1;
    if (isMonthlyDate) {
      // Extra payments include planned extra plus freed up minimum payments
      const freedUpMinimumPayments =
        monthlyMinimumPayments - activeMinimumPayments;
      const totalExtraPayments =
        monthlyPlannedExtra +
        accumulatedOneTimePayments +
        freedUpMinimumPayments;

      // Reset monthly budget with any accumulated one-time payments
      currentMonthBudget = activeMinimumPayments + totalExtraPayments;

      // Only allow extra payments if we have budget beyond minimum payments
      const canPayExtras = currentMonthBudget > activeMinimumPayments;

      // Add budget item to schedule
      const dateKey = date.toLocaleDateString();
      if (!datePayments.has(dateKey)) {
        datePayments.set(dateKey, new Map());
      }

      // Use a special key for budget items to ensure they appear first
      datePayments.get(dateKey)?.set('__budget', {
        date,
        type: PlanScheduleItemType.AddToDebtPaymentBudget,
        debtId: null,
        debtUpdateId: null,
        debtName: '',
        balanceBefore: null,
        totalPayment: currentMonthBudget,
        minimumPayment: activeMinimumPayments,
        extraPayment: totalExtraPayments,
        apr: null,
        principal: null,
        interest: null,
        balanceAfter: null,
        totalDebtBefore: null,
        totalDebtAfter: null,
        debtPaymentBudgetBefore: null,
        debtPaymentBudgetAfter: null,
        debtPaymentStatus: null,
        isPriorityDebt: null,
      });

      // First pass: Pay minimum payments for all debts
      for (const { debt, debtUpdate } of activeDebts) {
        if (paidOffDebts.has(debt.id)) continue;

        const currentBalance = debtBalances.get(debt.id) || 0;
        if (currentBalance <= 0) continue;

        // If we have a debt update for this period, use its values
        if (
          debtUpdate &&
          (!lastUsedDebtUpdates.has(debt.id) ||
            lastUsedDebtUpdates.get(debt.id)?.id !== debtUpdate.id)
        ) {
          // Update tracking
          lastUsedDebtUpdates.set(debt.id, debtUpdate);

          // When using a debt update, use its balance as the starting point
          const balanceBefore = debtUpdate.balance || currentBalance;
          const minimumPayment = Math.max(0, debtUpdate.minimumPayment || 0);
          const payment =
            debtUpdate.payment === null
              ? minimumPayment
              : Math.max(0, debtUpdate.payment);
          const interestCharged = Math.max(0, debtUpdate.interestCharged || 0);
          const principal = Math.max(0, payment - interestCharged);
          const extraPayment = Math.max(0, payment - minimumPayment);
          // For debt updates, we intentionally subtract the full payment amount from the balance
          // This reflects how financial institutions report balances after payments are made
          // where interest is charged but then immediately paid off as part of the payment
          const balanceAfter = debtUpdate.balance
            ? Math.max(0, debtUpdate.balance - payment)
            : Math.max(0, currentBalance - principal);

          // Update budget
          currentMonthBudget -= payment;

          // Use the last known debt update on or before this period for payment date
          const paymentDate = getPaymentDateForPeriod(debt, date);
          const dateKey = paymentDate.toLocaleDateString();
          if (!datePayments.has(dateKey)) {
            datePayments.set(dateKey, new Map());
          }

          // Add payment to schedule using actual values from debt update
          datePayments.get(dateKey)?.set(debt.id, {
            date: paymentDate,
            type: PlanScheduleItemType.DebtPayment,
            debtId: debt.id,
            debtUpdateId: debtUpdate?.id || null,
            debtName: debt.name || '',
            balanceBefore,
            totalPayment: payment,
            minimumPayment,
            extraPayment,
            apr: getEffectiveApr(debt, date),
            principal,
            interest: interestCharged,
            balanceAfter,
            totalDebtBefore: null,
            totalDebtAfter: null,
            debtPaymentBudgetBefore: currentMonthBudget + payment,
            debtPaymentBudgetAfter:
              currentMonthBudget < 0 ? payment : currentMonthBudget,
            debtPaymentStatus: null,
            isPriorityDebt: debt.id === priorityDebtId,
          });

          // Update balance tracking
          debtBalances.set(debt.id, balanceAfter);

          // Handle debt payoff
          if (balanceAfter === 0 && !paidOffDebts.has(debt.id)) {
            paidOffDebts.add(debt.id);
            const payoffKey = `${debt.id}_payoff`;
            datePayments.get(dateKey)?.set(payoffKey, {
              date: paymentDate,
              type: PlanScheduleItemType.DebtPayoff,
              debtId: debt.id,
              debtUpdateId: null,
              debtName: debt.name || '',
              balanceBefore: null,
              totalPayment: null,
              minimumPayment: null,
              extraPayment: null,
              apr: null,
              principal: null,
              interest: null,
              balanceAfter: null,
              totalDebtBefore: null,
              totalDebtAfter: null,
              debtPaymentBudgetBefore: null,
              debtPaymentBudgetAfter: null,
              debtPaymentStatus: null,
              isPriorityDebt: debt.id === priorityDebtId,
            });
          }
        } else {
          // Use estimated values as before
          const effectiveAPR = getEffectiveApr(debt, date);
          const monthlyInterest = calculateMonthlyInterest(
            currentBalance,
            effectiveAPR
          );
          const balanceWithInterest = currentBalance + monthlyInterest;
          const minimumPayment = calculateDebtMinimumPayment(
            debt,
            currentBalance,
            monthlyInterest,
            debtUpdate
          );

          // Calculate minimum payment, ensuring we don't overpay
          const actualMinimumPayment = Math.min(
            minimumPayment,
            currentBalance + monthlyInterest
          );

          // Calculate total payment including extra for highest priority debt
          const isPriorityDebt = debt.id === priorityDebtId;
          const availableExtra =
            isPriorityDebt && canPayExtras
              ? currentMonthBudget - activeMinimumPayments
              : 0;
          const totalPayment = Math.min(
            actualMinimumPayment + availableExtra,
            currentBalance + monthlyInterest
          );

          // Update budget
          currentMonthBudget -= totalPayment;

          // For calculated payments, we separate principal and interest
          // Principal is what actually reduces the balance
          const principal = totalPayment - monthlyInterest;
          const balanceAfter = Math.max(0, currentBalance - principal);

          // Use the last known debt update on or before this period for payment date
          const paymentDate = getPaymentDateForPeriod(debt, date);
          const dateKey = paymentDate.toLocaleDateString();
          if (!datePayments.has(dateKey)) {
            datePayments.set(dateKey, new Map());
          }

          // Add payment to schedule
          datePayments.get(dateKey)?.set(debt.id, {
            date: paymentDate,
            type: PlanScheduleItemType.DebtPayment,
            debtId: debt.id,
            debtUpdateId: null,
            debtName: debt.name || '',
            balanceBefore: balanceWithInterest,
            totalPayment: totalPayment,
            minimumPayment: actualMinimumPayment,
            extraPayment: totalPayment - actualMinimumPayment,
            apr: effectiveAPR,
            principal,
            interest: monthlyInterest,
            balanceAfter,
            totalDebtBefore: null,
            totalDebtAfter: null,
            debtPaymentBudgetBefore: currentMonthBudget + totalPayment,
            debtPaymentBudgetAfter:
              currentMonthBudget < 0 ? totalPayment : currentMonthBudget,
            debtPaymentStatus: null,
            isPriorityDebt,
          });

          // Update tracking after recording payment
          debtBalances.set(debt.id, balanceAfter);

          // Handle debt payoff
          if (balanceAfter === 0 && !paidOffDebts.has(debt.id)) {
            paidOffDebts.add(debt.id);
            const payoffKey = `${debt.id}_payoff`;
            datePayments.get(dateKey)?.set(payoffKey, {
              date: paymentDate,
              type: PlanScheduleItemType.DebtPayoff,
              debtId: debt.id,
              debtUpdateId: null,
              debtName: debt.name || '',
              balanceBefore: null,
              totalPayment: null,
              minimumPayment: null,
              extraPayment: null,
              apr: null,
              principal: null,
              interest: null,
              balanceAfter: null,
              totalDebtBefore: null,
              totalDebtAfter: null,
              debtPaymentBudgetBefore: null,
              debtPaymentBudgetAfter: null,
              debtPaymentStatus: null,
              isPriorityDebt,
            });
          }
        }
      }

      // Second pass: Distribute extra payments according to selected method
      if (currentMonthBudget > 0 && canPayExtras) {
        // Sort debts by APR for avalanche or balance for snowball
        const remainingDebts = activeDebts
          .filter(({ debt }) => !paidOffDebts.has(debt.id))
          .sort((a, b) => {
            if (plan.debtPayoffMethod === DebtPayoffMethod.Avalanche) {
              // Sort by APR descending, then balance ascending for ties
              const aprDiff = (b.debt.apr || 0) - (a.debt.apr || 0);
              return aprDiff !== 0 ? aprDiff : a.balance - b.balance;
            } else {
              // Snowball: Sort by balance ascending
              return a.balance - b.balance;
            }
          });

        for (const { debt } of remainingDebts) {
          if (currentMonthBudget <= 0) break;

          const currentBalance = debtBalances.get(debt.id) || 0;
          if (currentBalance <= 0) continue;

          const effectiveAPR = getEffectiveApr(debt, date);
          const monthlyInterest = calculateMonthlyInterest(
            currentBalance,
            effectiveAPR
          );

          // Calculate extra payment
          const extraPayment = Math.min(currentMonthBudget, currentBalance);

          if (extraPayment <= 0) continue;

          // Update budget
          currentMonthBudget -= extraPayment;

          // For extra payments, we also separate principal and interest
          // Only the principal portion reduces the balance
          const principal = extraPayment - monthlyInterest;
          let balanceAfter = Math.max(0, currentBalance - principal);

          // Use the last known debt update on or before this period for payment date
          const paymentDate = getPaymentDateForPeriod(debt, date);
          const dateKey = paymentDate.toLocaleDateString();
          if (!datePayments.has(dateKey)) {
            datePayments.set(dateKey, new Map());
          }

          // Update existing payment in schedule only if there's no matching debt update
          // with a payment value. If there is, we skip updating the existing payment
          // to give precedence to debt update's values that was set earlier in this logic.
          const existingPayment = datePayments.get(dateKey)?.get(debt.id);
          const matchingDebtUpdate = debt?.updates?.find(
            (u) => existingPayment && existingPayment.debtUpdateId === u.id
          );
          const matchingDebtUpdatePayment = matchingDebtUpdate?.payment ?? null;
          if (existingPayment && matchingDebtUpdatePayment === null) {
            balanceAfter = (existingPayment.balanceAfter ?? 0) - extraPayment;
            const updatedPayment = {
              ...existingPayment,
              totalPayment: (existingPayment.totalPayment ?? 0) + extraPayment,
              extraPayment: (existingPayment.extraPayment ?? 0) + extraPayment,
              principal: (existingPayment.principal ?? 0) + extraPayment,
              balanceAfter,
              debtPaymentBudgetBefore:
                existingPayment.debtPaymentBudgetBefore ?? 0,
              debtPaymentBudgetAfter:
                (existingPayment.debtPaymentBudgetAfter ?? 0) - extraPayment,
            };
            datePayments.get(dateKey)?.set(debt.id, updatedPayment);
          }

          // Update tracking after recording payment
          debtBalances.set(debt.id, balanceAfter);

          // Handle debt payoff
          if (balanceAfter === 0 && !paidOffDebts.has(debt.id)) {
            paidOffDebts.add(debt.id);
            // Add minimum payment back to budget for snowball
            const minimumPayment = calculateDebtMinimumPayment(
              debt,
              currentBalance,
              monthlyInterest,
              matchingDebtUpdate
            );
            currentMonthBudget += minimumPayment;
            const payoffKey = `${debt.id}_payoff`;
            datePayments.get(dateKey)?.set(payoffKey, {
              date: paymentDate,
              type: PlanScheduleItemType.DebtPayoff,
              debtId: debt.id,
              debtUpdateId: null,
              debtName: debt.name || '',
              balanceBefore: null,
              totalPayment: null,
              minimumPayment: null,
              extraPayment: null,
              apr: null,
              principal: null,
              interest: null,
              balanceAfter: null,
              totalDebtBefore: null,
              totalDebtAfter: null,
              debtPaymentBudgetBefore: null,
              debtPaymentBudgetAfter: null,
              debtPaymentStatus: null,
              isPriorityDebt: debt.id === priorityDebtId,
            });
          }
        }
      }

      // Reset accumulated one-time payments after adding to budget
      accumulatedOneTimePayments = 0;
    }
  };

  // Process each month until all debts are paid
  let day = 0;
  while (
    !Array.from(debtBalances.values()).every((bal) => bal <= 0) &&
    day < MAX_DAYS
  ) {
    const stepDate = new Date(currentDate);
    stepDate.setDate(stepDate.getDate() + day);

    // Get one-time payments scheduled for this date
    const hasOneTimePayment = plan?.plannedExtraDebtPayments?.some(
      (p) =>
        p.frequencyType === PlannedExtraDebtPaymentFrequencyType.OneTime &&
        p.startDate &&
        new Date(p.startDate).toDateString() === stepDate.toDateString()
    );

    // Process on the first of each month or when there's a one-time payment
    if (stepDate.getDate() === 1 || hasOneTimePayment) {
      processDate(stepDate);
    }

    day++;
  }

  // Sort datePayments by date
  const sortedDatePayments = new Map(
    Array.from(datePayments.entries()).sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    )
  );

  // Convert to sorted array
  let result: PlanScheduleItem[] = [];
  for (const [dateKey, payments] of sortedDatePayments.entries()) {
    const sortedPayments = Array.from(payments.values()).sort((a, b) => {
      // Budget items always come first
      if (a.type === PlanScheduleItemType.AddToDebtPaymentBudget) return -1;
      if (b.type === PlanScheduleItemType.AddToDebtPaymentBudget) return 1;

      // Payoff items should immediately follow their debt payment
      if (a.type === PlanScheduleItemType.DebtPayoff) {
        // If b is the corresponding debt payment, a should come after
        if (
          b.type === PlanScheduleItemType.DebtPayment &&
          b.debtId === a.debtId
        )
          return 1;
        // If b is a different debt's payment or payoff, use debt priority order
        return 0;
      }
      if (b.type === PlanScheduleItemType.DebtPayoff) {
        // If a is the corresponding debt payment, b should come after
        if (
          a.type === PlanScheduleItemType.DebtPayment &&
          a.debtId === b.debtId
        )
          return -1;
        // If a is a different debt's payment or payoff, use debt priority order
        return 0;
      }

      // For debt payments, sort by priority
      if (
        a.type === PlanScheduleItemType.DebtPayment &&
        b.type === PlanScheduleItemType.DebtPayment
      ) {
        const debtA = plan?.debts.find((d) => d.id === a.debtId);
        const debtB = plan?.debts.find((d) => d.id === b.debtId);

        if (debtA && debtB) {
          if (plan?.debtPayoffMethod === DebtPayoffMethod.Snowball) {
            // For snowball, sort by balance (lowest first)
            const balanceA = a.balanceBefore ?? Infinity;
            const balanceB = b.balanceBefore ?? Infinity;
            return balanceA - balanceB;
          } else {
            // For avalanche, sort by effective APR (highest first)
            const dateToCheck = new Date(currentDate);
            const aprA = getEffectiveApr(debtA, dateToCheck);
            const aprB = getEffectiveApr(debtB, dateToCheck);
            return aprB - aprA;
          }
        }
      }

      // For items of the same debt, maintain payment -> payoff order
      if (a.debtId === b.debtId) {
        if (a.type === PlanScheduleItemType.DebtPayment) return -1;
        if (b.type === PlanScheduleItemType.DebtPayment) return 1;
      }

      // Default to maintaining original order
      return 0;
    });
    result.push(...sortedPayments);
  }

  const allDebtBalances = new Map(
    plan?.debts.map((d) => [d.id, getDebtInitialBalance(d)])
  );

  result = result.map((item) => {
    if (item.debtId && item.type === PlanScheduleItemType.DebtPayment) {
      allDebtBalances.set(item.debtId, item.balanceBefore ?? 0);
      const totalDebtBefore = Array.from(allDebtBalances.values()).reduce(
        (a, b) => (round(a) ?? 0) + (round(b) ?? 0),
        0
      );
      allDebtBalances.set(item.debtId, item.balanceAfter ?? 0);
      const totalDebtAfter = Array.from(allDebtBalances.values()).reduce(
        (a, b) => (round(a) ?? 0) + (round(b) ?? 0),
        0
      );
      return { ...item, totalDebtBefore, totalDebtAfter };
    }
    return item;
  });

  // Add a debt-free item if the last item is a debt payoff
  const lastItem = result[result.length - 1];
  if (lastItem && lastItem.type === PlanScheduleItemType.DebtPayoff) {
    result.push({
      date: lastItem.date,
      type: PlanScheduleItemType.DebtFree,
      debtId: null,
      debtUpdateId: null,
      debtName: '',
      balanceBefore: null,
      totalPayment: null,
      minimumPayment: null,
      extraPayment: null,
      apr: null,
      principal: null,
      interest: null,
      balanceAfter: null,
      totalDebtBefore: null,
      totalDebtAfter: null,
      debtPaymentBudgetBefore: null,
      debtPaymentBudgetAfter: null,
      debtPaymentStatus: null,
      isPriorityDebt: null,
    });
  }

  // Round all numeric values to 4 decimal places before returning the result
  result = result.map((item) => {
    return {
      date: item.date,
      type: item.type,
      debtId: item.debtId,
      debtUpdateId: item.debtUpdateId,
      debtName: item.debtName,
      balanceBefore: round(item.balanceBefore),
      totalPayment: round(item.totalPayment),
      minimumPayment: round(item.minimumPayment),
      extraPayment: round(item.extraPayment),
      apr: item.apr,
      principal: round(item.principal),
      interest: round(item.interest),
      balanceAfter: round(item.balanceAfter),
      totalDebtBefore: round(item.totalDebtBefore),
      totalDebtAfter: round(item.totalDebtAfter),
      debtPaymentBudgetBefore: round(item.debtPaymentBudgetBefore),
      debtPaymentBudgetAfter: round(item.debtPaymentBudgetAfter),
      debtPaymentStatus: item.debtPaymentStatus,
      // isPriorityDebt: item.isPriorityDebt,
      isPriorityDebt: null,
    };
  });

  result = result.map((item) => {
    if (plan && item.type === PlanScheduleItemType.DebtPayment) {
      return {
        ...item,
        debtPaymentStatus: getDebtPaymentStatus(plan, item),
      };
    }
    return item;
  });

  return result;
};
