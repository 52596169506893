import { differenceInDays, parseISO, startOfDay } from 'date-fns';
import { getNonUnluckyNumber } from './getNonUnluckyNumber';

export function getDebtFreeCountdown(
  debtFreeDate: string | null
): number | null {
  if (!debtFreeDate) return null;

  const debtFreeCountdown = differenceInDays(
    startOfDay(parseISO(debtFreeDate)),
    startOfDay(new Date())
  );

  return Math.max(0, getNonUnluckyNumber(debtFreeCountdown));
}
