import { useEffect, useState } from 'react';
import { z } from 'zod';
import { Profile } from '@/types';
import { createSupabaseClient } from '@/utils/supabase/client';
import { identifyUserProfileInExternalServices } from '@/utils';

const profileUpdateSchema = z
  .object({
    defaultPlanId: z.string().uuid().nullable().optional(),
    debtFreeDate: z.string().nullable().optional(),
  })
  .transform((data) => ({
    ...(data.defaultPlanId !== undefined && {
      default_plan_id: data.defaultPlanId,
    }),
    ...(data.debtFreeDate !== undefined && {
      debt_free_date: data.debtFreeDate,
    }),
  }));

interface UseProfileProps {
  fetchOnMount?: boolean;
}

export interface UseProfileReturn {
  data: Profile | null;
  error: string | null;
  isReady: boolean;
  isFetching: boolean | null;
  isUpdating: boolean | null;
  isLoading: boolean;
  fetchData: () => Promise<{ data?: Profile | null; error?: string | null }>;
  updateData: (
    id: string,
    values: z.input<typeof profileUpdateSchema>
  ) => Promise<{ success?: boolean; error?: string | null }>;
  clearData: () => void;
}

export function useProfile({
  fetchOnMount,
}: UseProfileProps = {}): UseProfileReturn {
  const [data, setData] = useState<Profile | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const isLoading = isFetching || isUpdating;

  const fetchData = async (): Promise<{
    data?: Profile | null;
    error?: string | null;
  }> => {
    setIsFetching(true);
    try {
      const res = await createSupabaseClient()
        .from('profiles')
        .select(
          `
            id,
            username,
            displayName:display_name,
            defaultPlanId:default_plan_id,
            debtFreeDate:debt_free_date,
            role:role,
            createdAt:created_at,
            updatedAt:updated_at
          `
        )
        .returns<(Profile | null)[]>()
        .single();
      if (res.error?.code === 'PGRST116') {
        setData(null);
        setIsFetching(false);
        return { data: null };
      } else if (res.error) {
        throw res.error;
      }
      if (!data && res.data) {
        identifyUserProfileInExternalServices(res.data);
      }
      setData(res.data);
      return { data: res.data };
    } catch (e) {
      console.error('Error fetching profile:', e);
      const errorMessage = `Failed to load profile: ${(e as Error)?.message}`;
      setError(errorMessage);
      return { error: errorMessage };
    } finally {
      setIsReady(true);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (fetchOnMount !== false) fetchData();
  }, []);

  const updateData = async (
    id: string,
    values: z.input<typeof profileUpdateSchema>
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsUpdating(true);
    try {
      const res = await createSupabaseClient()
        .from('profiles')
        .update(profileUpdateSchema.parse(values))
        .eq('id', id);
      if (res.error) throw res.error;
      setData((currentProfile) => {
        if (!currentProfile) return null;
        const updatedProfile = { ...currentProfile, ...values };
        identifyUserProfileInExternalServices(updatedProfile);
        return updatedProfile;
      });
      setIsUpdating(false);
      await fetchData();
      return { success: true };
    } catch (e) {
      console.error('Error updating profile:', e);
      return { error: `Failed to update profile: ${(e as Error)?.message}` };
    } finally {
      setIsUpdating(false);
    }
  };

  const clearData = () => {
    setData(null);
    setError(null);
    setIsReady(false);
  };

  return {
    data,
    error,
    isReady,
    isFetching,
    isUpdating,
    isLoading,
    fetchData,
    updateData,
    clearData,
  };
}
