import { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { z } from 'zod';
import {
  Plan,
  DebtPayoffMethod,
  DebtCategory,
  MinimumPaymentCalculationType,
  PaymentFrequencyType,
  Debt,
  PlannedExtraDebtPayment,
  PlannedExtraDebtPaymentFrequencyType,
  DebtUpdate,
  DebtSnakeCase,
} from '@/types';
import { createSupabaseClient } from '@/utils/supabase/client';
import { getInitialDebtUpdateValues, sortDebts } from '@/utils';

const planCreateSchema = z
  .object({
    name: z.string().nullable(),
  })
  .transform((data) => ({
    name: data.name,
  }));

const planUpdateSchema = z
  .object({
    name: z.string().nullable().optional(),
    debtPayoffMethod: z.nativeEnum(DebtPayoffMethod).nullable().optional(),
    debtPayoffCustomOrder: z.array(z.string()).nullable().optional(),
  })
  .transform((data) => ({
    ...(data.name !== undefined && { name: data.name }),
    ...(data.debtPayoffMethod !== undefined && {
      debt_payoff_method: data.debtPayoffMethod,
    }),
    ...(data.debtPayoffCustomOrder !== undefined && {
      debt_payoff_custom_order: data.debtPayoffCustomOrder,
    }),
  }));

const debtCreateSchema = z
  .object({
    planId: z.string(),
    name: z.string().nullable(),
    category: z.nativeEnum(DebtCategory).nullable(),
    apr: z.number().nullable(),
    initialBalance: z.number().nullable(),
    initialNextPaymentDueDate: z.string().nullable(),
    minimumPaymentCalculationType: z
      .nativeEnum(MinimumPaymentCalculationType)
      .nullable(),
    minimumPaymentFixed: z.number().nullable().optional(),
    minimumPaymentPercent: z.number().nullable().optional(),
    lowestPaymentAllowed: z.number().nullable().optional(),
    paymentFrequencyType: z.nativeEnum(PaymentFrequencyType).nullable(),
    paymentIntervalDays: z.number().nullable().optional(),
    promoApr: z.number().nullable().optional(),
    promoAprEndDate: z.string().nullable().optional(),
    note: z.string().nullable().optional(),
  })
  .transform((data) => ({
    plan_id: data.planId,
    name: data.name,
    category: data.category,
    apr: data.apr,
    initial_balance: data.initialBalance,
    initial_next_payment_due_date: data.initialNextPaymentDueDate,
    minimum_payment_calculation_type: data.minimumPaymentCalculationType,
    minimum_payment_fixed: data.minimumPaymentFixed,
    minimum_payment_percent: data.minimumPaymentPercent,
    lowest_payment_allowed: data.lowestPaymentAllowed,
    payment_frequency_type: data.paymentFrequencyType,
    payment_interval_days: data.paymentIntervalDays,
    promo_apr: data.promoApr,
    promo_apr_end_date: data.promoAprEndDate,
    note: data.note,
  }));

const debtUpdateSchema = z
  .object({
    name: z.string().nullable().optional(),
    category: z.nativeEnum(DebtCategory).nullable().optional(),
    apr: z.number().nullable().optional(),
    initialBalance: z.number().nullable().optional(),
    initialNextPaymentDueDate: z.string().nullable().optional(),
    minimumPaymentCalculationType: z
      .nativeEnum(MinimumPaymentCalculationType)
      .nullable()
      .optional(),
    minimumPaymentFixed: z.number().nullable().optional(),
    minimumPaymentPercent: z.number().nullable().optional(),
    lowestPaymentAllowed: z.number().nullable().optional(),
    paymentFrequencyType: z.nativeEnum(PaymentFrequencyType).optional(),
    paymentIntervalDays: z.number().nullable().optional(),
    promoApr: z.number().nullable().optional(),
    promoAprEndDate: z.string().nullable().optional(),
    note: z.string().nullable().optional(),
  })
  .transform((data) => ({
    ...(data.name !== undefined && { name: data.name }),
    ...(data.category !== undefined && { category: data.category }),
    ...(data.apr !== undefined && { apr: data.apr }),
    ...(data.initialBalance !== undefined && {
      initial_balance: data.initialBalance,
    }),
    ...(data.initialNextPaymentDueDate !== undefined && {
      initial_next_payment_due_date: data.initialNextPaymentDueDate,
    }),
    ...(data.minimumPaymentCalculationType !== undefined && {
      minimum_payment_calculation_type: data.minimumPaymentCalculationType,
    }),
    ...(data.minimumPaymentFixed !== undefined && {
      minimum_payment_fixed: data.minimumPaymentFixed,
    }),
    ...(data.minimumPaymentPercent !== undefined && {
      minimum_payment_percent: data.minimumPaymentPercent,
    }),
    ...(data.lowestPaymentAllowed !== undefined && {
      lowest_payment_allowed: data.lowestPaymentAllowed,
    }),
    ...(data.paymentFrequencyType !== undefined && {
      payment_frequency_type: data.paymentFrequencyType,
    }),
    ...(data.paymentIntervalDays !== undefined && {
      payment_interval_days: data.paymentIntervalDays,
    }),
    ...(data.promoApr !== undefined && { promo_apr: data.promoApr }),
    ...(data.promoAprEndDate !== undefined && {
      promo_apr_end_date: data.promoAprEndDate,
    }),
    ...(data.note !== undefined && { note: data.note }),
  }));

const plannedExtraDebtPaymentCreateSchema = z
  .object({
    planId: z.string(),
    frequencyType: z
      .nativeEnum(PlannedExtraDebtPaymentFrequencyType)
      .nullable(),
    intervalDays: z.number().nullable().optional(),
    startDate: z.string().nullable(),
    endDate: z.string().nullable().optional(),
    amount: z.number().nullable(),
  })
  .transform((data) => ({
    plan_id: data.planId,
    frequency_type: data.frequencyType,
    interval_days: data.intervalDays,
    start_date: data.startDate,
    end_date: data.endDate,
    amount: data.amount,
  }));

const plannedExtraDebtPaymentUpdateSchema = z
  .object({
    frequencyType: z
      .nativeEnum(PlannedExtraDebtPaymentFrequencyType)
      .nullable()
      .optional(),
    intervalDays: z.number().nullable().optional(),
    startDate: z.string().nullable().optional(),
    endDate: z.string().nullable().optional(),
    amount: z.number().nullable().optional(),
  })
  .transform((data) => ({
    ...(data.frequencyType !== undefined && {
      frequency_type: data.frequencyType,
    }),
    ...(data.intervalDays !== undefined && {
      interval_days: data.intervalDays,
    }),
    ...(data.startDate !== undefined && { start_date: data.startDate }),
    ...(data.endDate !== undefined && { end_date: data.endDate }),
    ...(data.amount !== undefined && { amount: data.amount }),
  }));

const debtUpdateCreateSchema = z
  .object({
    debtId: z.string(),
    startDate: z.string().nullable().optional(),
    endDate: z.string().nullable().optional(),
    balance: z.number().nullable().optional(),
    minimumPayment: z.number().nullable().optional(),
    interestCharged: z.number().nullable().optional(),
    payment: z.number().nullable().optional(),
  })
  .transform((data) => ({
    debt_id: data.debtId,
    start_date: data.startDate,
    end_date: data.endDate,
    balance: data.balance,
    minimum_payment: data.minimumPayment,
    interest_charged: data.interestCharged,
    payment: data.payment,
  }));

const debtUpdateUpdateSchema = z
  .object({
    startDate: z.string().nullable().optional(),
    endDate: z.string().nullable().optional(),
    balance: z.number().nullable().optional(),
    minimumPayment: z.number().nullable().optional(),
    interestCharged: z.number().nullable().optional(),
    payment: z.number().nullable().optional(),
  })
  .transform((data) => ({
    ...(data.startDate !== undefined && { start_date: data.startDate }),
    ...(data.endDate !== undefined && { end_date: data.endDate }),
    ...(data.balance !== undefined && { balance: data.balance }),
    ...(data.minimumPayment !== undefined && {
      minimum_payment: data.minimumPayment,
    }),
    ...(data.interestCharged !== undefined && {
      interest_charged: data.interestCharged,
    }),
    ...(data.payment !== undefined && { payment: data.payment }),
  }));

interface UsePlansProps {
  fetchOnMount?: boolean;
}

export interface UsePlansReturn {
  data: Plan[];
  error: string | null;
  isReady: boolean;
  isFetching: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  currentPlan: Plan | null;
  currentDebt: Debt | null;
  fetchPlans: () => Promise<{ data?: Plan[]; error?: string | null }>;
  createPlan: (
    values: z.input<typeof planCreateSchema>
  ) => Promise<{ data?: Pick<Plan, 'id'>; error?: string | null }>;
  updatePlan: (
    id: string,
    values: z.input<typeof planUpdateSchema>
  ) => Promise<{ success?: boolean; error?: string | null }>;
  deletePlan: (
    id: string
  ) => Promise<{ success?: boolean; error?: string | null }>;
  createDebt: (
    values: z.input<typeof debtCreateSchema>
  ) => Promise<{ data?: Pick<Debt, 'id'>; error?: string | null }>;
  updateDebt: (
    id: string,
    values: z.input<typeof debtUpdateSchema>
  ) => Promise<{ success?: boolean; error?: string | null }>;
  deleteDebt: (
    id: string
  ) => Promise<{ success?: boolean; error?: string | null }>;
  createPlannedExtraDebtPayment: (
    values: z.input<typeof plannedExtraDebtPaymentCreateSchema>
  ) => Promise<{
    data?: Pick<PlannedExtraDebtPayment, 'id'>;
    error?: string | null;
  }>;
  updatePlannedExtraDebtPayment: (
    id: string,
    values: z.input<typeof plannedExtraDebtPaymentUpdateSchema>
  ) => Promise<{ success?: boolean; error?: string | null }>;
  deletePlannedExtraDebtPayment: (
    id: string
  ) => Promise<{ success?: boolean; error?: string | null }>;
  createDebtUpdate: (
    values: z.input<typeof debtUpdateCreateSchema>
  ) => Promise<{ data?: Pick<DebtUpdate, 'id'>; error?: string | null }>;
  updateDebtUpdate: (
    id: string,
    values: z.input<typeof debtUpdateUpdateSchema>
  ) => Promise<{ success?: boolean; error?: string | null }>;
  deleteDebtUpdate: (
    id: string
  ) => Promise<{ success?: boolean; error?: string | null }>;
  clearData: () => void;
}

export function usePlans({ fetchOnMount }: UsePlansProps = {}): UsePlansReturn {
  const [data, setData] = useState<Plan[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const isLoading = isFetching || isCreating || isUpdating || isDeleting;

  const params = useParams();
  const currentPlan = data.find((plan) => plan.id === params.planId) ?? null;
  const currentDebt =
    currentPlan?.debts.find((debt) => debt.id === params.debtId) ?? null;

  const fetchPlans = async (): Promise<{
    data?: Plan[];
    error?: string | null;
  }> => {
    setIsFetching(true);
    try {
      const res = await createSupabaseClient()
        .from('plans')
        .select(
          `
            id,
            name,
            debtPayoffMethod:debt_payoff_method,
            debtPayoffCustomOrder:debt_payoff_custom_order,
            createdAt:created_at,
            updatedAt:updated_at,
            debts(
              id,
              planId:plan_id,
              name,
              category,
              apr,
              initialBalance:initial_balance,
              initialNextPaymentDueDate:initial_next_payment_due_date,
              minimumPaymentCalculationType:minimum_payment_calculation_type,
              minimumPaymentFixed:minimum_payment_fixed,
              minimumPaymentPercent:minimum_payment_percent,
              lowestPaymentAllowed:lowest_payment_allowed,
              paymentFrequencyType:payment_frequency_type,
              paymentIntervalDays:payment_interval_days,
              promoApr:promo_apr,
              promoAprEndDate:promo_apr_end_date,
              note,
              createdAt:created_at,
              updatedAt:updated_at,
              updates:debt_updates (
                id,
                debtId:debt_id,
                startDate:start_date,
                endDate:end_date,
                balance,
                minimumPayment:minimum_payment,
                interestCharged:interest_charged,
                payment:payment,
                createdAt:created_at,
                updatedAt:updated_at
              )
            ),
            plannedExtraDebtPayments:planned_extra_debt_payments (
              id,
              planId:plan_id,
              frequencyType:frequency_type,
              intervalDays:interval_days,
              startDate:start_date,
              endDate:end_date,
              amount,
              createdAt:created_at,
              updatedAt:updated_at
            )
          `
        )
        .order('created_at', { ascending: true })
        .returns<Plan[]>();
      if (res.error?.code === 'PGRST116') {
        setData([]);
        setIsFetching(false);
        return { data: [] };
      } else if (res.error) {
        throw res.error;
      }
      // const resData = res.data.map((plan) => {
      //   // plan.debts = plan.debts.map((debt) => {
      //   //   debt.updates = debt.updates?.map((update) => {
      //   //     if (update.id === '2c00ca7c-f38a-4f19-813f-505a2a87b06c') {
      //   //       console.log({ update });
      //   //       update.startDate = '2024-09-05T04:00:00+00:00';
      //   //     }
      //   //     return update;
      //   //   });
      //   //   return debt;
      //   // });
      //   // plan.debts = plan.debts.filter((d) => {
      //   //   return d.id !== '1522ff37-08a9-4c8d-93d6-5cabbe78b599';
      //   // });
      //   // plan.debts = plan.debts.map((debt) => {
      //   //   debt.updates = debt.updates
      //   //     ?.sort((a, b) => {
      //   //       if (a.startDate && b.startDate) {
      //   //         return (
      //   //           new Date(a.startDate).getTime() -
      //   //           new Date(b.startDate).getTime()
      //   //         );
      //   //       }
      //   //       return 0;
      //   //     })
      //   //     .slice(0, 1);
      //   //   return debt;
      //   // });
      //   return plan;
      // });
      const syncedData = await syncDebtPayoffCustomOrderIfNeeded(res.data);
      setData(syncedData);
      return { data: syncedData };
    } catch (e) {
      console.error('Error fetching plans:', e);
      const errorMessage = `Failed to load plans: ${(e as Error)?.message}`;
      setError(errorMessage);
      return { error: errorMessage };
    } finally {
      setIsReady(true);
      setIsFetching(false);
    }
  };

  const createPlan = async (
    values: z.input<typeof planCreateSchema>
  ): Promise<{ data?: Pick<Plan, 'id'>; error?: string | null }> => {
    setIsCreating(true);
    try {
      const res = await createSupabaseClient()
        .from('plans')
        .insert(planCreateSchema.parse(values))
        .select('id')
        .returns<Pick<Plan, 'id'>[]>()
        .single();
      if (res.error) throw res.error;
      if (!res.data) throw new Error('No data returned from insert');
      setIsCreating(false);
      // Update local state
      await fetchPlans();
      return { data: { id: res.data.id } };
    } catch (e) {
      console.error('Error creating plan:', e);
      return { error: `Failed to create plan: ${(e as Error)?.message}` };
    } finally {
      setIsCreating(false);
    }
  };

  const updatePlan = async (
    id: string,
    values: z.input<typeof planUpdateSchema>,
    refetchAfterUpdate = true
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsUpdating(true);
    try {
      const parsedValues = planUpdateSchema.parse(values);
      const res = await createSupabaseClient()
        .from('plans')
        .update(parsedValues)
        .eq('id', id);
      if (res.error) throw res.error;
      // Update local state
      setData((currentPlans) =>
        currentPlans.map((plan) =>
          plan.id === id ? { ...plan, ...parsedValues } : plan
        )
      );
      setIsUpdating(false);
      if (refetchAfterUpdate) await fetchPlans();
      return { success: true };
    } catch (e) {
      console.error('Error updating plan:', e);
      return { error: `Failed to update plan: ${(e as Error)?.message}` };
    } finally {
      setIsUpdating(false);
    }
  };

  const deletePlan = async (
    id: string
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsDeleting(true);
    try {
      const res = await createSupabaseClient()
        .from('plans')
        .delete()
        .eq('id', id);
      if (res.error) throw res.error;
      setIsDeleting(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      return { error: `Failed to delete plan: ${(e as Error)?.message}` };
    } finally {
      setIsDeleting(false);
    }
  };

  const createDebt = async (
    values: z.input<typeof debtCreateSchema>
  ): Promise<{ data?: Pick<Debt, 'id'>; error?: string | null }> => {
    setIsCreating(true);
    console.log('createDebt()...', { values });
    const parsedValues = debtCreateSchema.parse(values);
    try {
      const res = await createSupabaseClient()
        .from('debts')
        .insert(parsedValues)
        .select('id')
        .returns<Pick<Debt, 'id'>[]>()
        .single();
      if (res.error) throw res.error;
      if (!res.data) throw new Error('No data returned from insert');
      console.log({ res });
      const res2 = await createInitialDebtUpdate(
        res.data.id,
        parsedValues as Partial<DebtSnakeCase>
      );
      if (res2.error) throw res2.error;
      if (!res2.data) throw new Error('No data returned from insert');
      console.log({ res2 });
      setIsCreating(false);
      await fetchPlans();
      return { data: { id: res.data.id } };
    } catch (e) {
      return { error: `Failed to create debt: ${(e as Error)?.message}` };
    } finally {
      setIsCreating(false);
    }
  };

  const updateDebt = async (
    id: string,
    values: z.input<typeof debtUpdateSchema>
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsUpdating(true);
    try {
      const parsedValues = debtUpdateSchema.parse(values);
      const res = await createSupabaseClient()
        .from('debts')
        .update(parsedValues)
        .eq('id', id);
      if (res.error) throw res.error;
      // Update local state
      setData((currentPlans) =>
        currentPlans.map((plan) => ({
          ...plan,
          debts: plan.debts?.map((debt) =>
            debt.id === id ? { ...debt, ...parsedValues } : debt
          ),
        }))
      );
      setIsUpdating(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      console.error('Error updating debt:', e);
      return { error: `Failed to update debt: ${(e as Error)?.message}` };
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteDebt = async (
    id: string
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsDeleting(true);
    try {
      const res = await createSupabaseClient()
        .from('debts')
        .delete()
        .eq('id', id);
      if (res.error) throw res.error;
      setIsDeleting(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      return { error: `Failed to delete debt: ${(e as Error)?.message}` };
    } finally {
      setIsDeleting(false);
    }
  };

  const createPlannedExtraDebtPayment = async (
    values: z.input<typeof plannedExtraDebtPaymentCreateSchema>
  ): Promise<{
    data?: Pick<PlannedExtraDebtPayment, 'id'>;
    error?: string | null;
  }> => {
    setIsCreating(true);
    try {
      const res = await createSupabaseClient()
        .from('planned_extra_debt_payments')
        .insert(plannedExtraDebtPaymentCreateSchema.parse(values))
        .select('id')
        .returns<Pick<PlannedExtraDebtPayment, 'id'>[]>()
        .single();
      if (res.error) throw res.error;
      if (!res.data) throw new Error('No data returned from insert');
      setIsCreating(false);
      await fetchPlans();
      return { data: { id: res.data.id } };
    } catch (e) {
      return {
        error: `Failed to create planned extra debt payment: ${(e as Error)?.message}`,
      };
    } finally {
      setIsCreating(false);
    }
  };

  const updatePlannedExtraDebtPayment = async (
    id: string,
    values: z.input<typeof plannedExtraDebtPaymentUpdateSchema>
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsUpdating(true);
    try {
      const parsedValues = plannedExtraDebtPaymentUpdateSchema.parse(values);
      const res = await createSupabaseClient()
        .from('planned_extra_debt_payments')
        .update(parsedValues)
        .eq('id', id);
      if (res.error) throw res.error;
      // Update local state
      setData((currentPlans) =>
        currentPlans.map((plan) => ({
          ...plan,
          plannedExtraDebtPayments: plan.plannedExtraDebtPayments?.map(
            (payment) =>
              payment.id === id ? { ...payment, ...parsedValues } : payment
          ),
        }))
      );
      setIsUpdating(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      console.error('Error updating planned extra debt payment:', e);
      return {
        error: `Failed to update planned extra debt payment: ${(e as Error)?.message}`,
      };
    } finally {
      setIsUpdating(false);
    }
  };

  const deletePlannedExtraDebtPayment = async (
    id: string
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsDeleting(true);
    try {
      const res = await createSupabaseClient()
        .from('planned_extra_debt_payments')
        .delete()
        .eq('id', id);
      if (res.error) throw res.error;
      setIsDeleting(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      return {
        error: `Failed to delete planned extra debt payment: ${(e as Error)?.message}`,
      };
    } finally {
      setIsDeleting(false);
    }
  };

  const createInitialDebtUpdate = async (
    debtId: string,
    debtValues: Partial<DebtSnakeCase>
  ): Promise<{ data?: Pick<DebtUpdate, 'id'>; error?: string | null }> => {
    console.log('createInitialDebtUpdate()...', { debtId, debtValues });
    return createDebtUpdate(
      getInitialDebtUpdateValues(debtId, debtValues),
      false
    );
  };

  const createDebtUpdate = async (
    values: z.input<typeof debtUpdateCreateSchema>,
    refetchPlansAfter = true
  ): Promise<{ data?: Pick<DebtUpdate, 'id'>; error?: string | null }> => {
    setIsCreating(true);
    try {
      const res = await createSupabaseClient()
        .from('debt_updates')
        .insert(debtUpdateCreateSchema.parse(values))
        .select('id')
        .returns<Pick<DebtUpdate, 'id'>[]>()
        .single();
      if (res.error) throw res.error;
      if (!res.data) throw new Error('No data returned from insert');
      setIsCreating(false);
      if (refetchPlansAfter) {
        await fetchPlans();
      }
      return { data: { id: res.data.id } };
    } catch (e) {
      return {
        error: `Failed to create debt update: ${(e as Error)?.message}`,
      };
    } finally {
      setIsCreating(false);
    }
  };

  const updateDebtUpdate = async (
    id: string,
    values: z.input<typeof debtUpdateUpdateSchema>
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsUpdating(true);
    try {
      const parsedValues = debtUpdateUpdateSchema.parse(values);
      const res = await createSupabaseClient()
        .from('debt_updates')
        .update(parsedValues)
        .eq('id', id);
      if (res.error) throw res.error;
      // Update local state
      setData((currentPlans) =>
        currentPlans.map((plan) => ({
          ...plan,
          debts:
            plan.debts?.map((debt) => ({
              ...debt,
              updates: debt.updates?.map((update) =>
                update.id === id ? { ...update, ...parsedValues } : update
              ),
            })) || [],
        }))
      );
      setIsUpdating(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      console.error('Error updating debt update:', e);
      return {
        error: `Failed to update debt update: ${(e as Error)?.message}`,
      };
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteDebtUpdate = async (
    id: string
  ): Promise<{ success?: boolean; error?: string | null }> => {
    setIsDeleting(true);
    try {
      const res = await createSupabaseClient()
        .from('debt_updates')
        .delete()
        .eq('id', id);
      if (res.error) throw res.error;
      setIsDeleting(false);
      await fetchPlans();
      return { success: true };
    } catch (e) {
      return {
        error: `Failed to delete debt update: ${(e as Error)?.message}`,
      };
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (fetchOnMount !== false) fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearData = () => {
    setData([]);
    setError(null);
    setIsReady(false);
  };

  const syncDebtPayoffCustomOrderIfNeeded = async (
    plans: Plan[]
  ): Promise<Plan[]> => {
    // Iterate through each plan
    const updatedPlans = [...plans];
    for (let [i, plan] of updatedPlans.entries()) {
      // Check if the plan has a custom order
      const debtIds = sortDebts(plan.debts).map((debt) => debt.id);
      let currentOrder = plan.debtPayoffCustomOrder ?? [];
      let needsUpdate = false;

      // Remove any debt IDs that no longer exist in the plan
      const validOrder = currentOrder.filter((debtId) =>
        debtIds.includes(debtId)
      );
      if (validOrder.length !== currentOrder.length) {
        needsUpdate = true;
        currentOrder = validOrder;
      }

      // Add any missing debt IDs to the end of the array
      const missingDebtIds = debtIds.filter((id) => !currentOrder.includes(id));
      if (missingDebtIds.length > 0) {
        needsUpdate = true;
        currentOrder = [...currentOrder, ...missingDebtIds];
      }

      // Update the plan if changes were needed
      if (needsUpdate) {
        await updatePlan(
          plan.id,
          {
            debtPayoffCustomOrder: currentOrder,
          },
          false
        );
        updatedPlans[i] = {
          ...plan,
          debtPayoffCustomOrder: currentOrder,
        };
      }
    }
    return updatedPlans;
  };

  return {
    data,
    error,
    isReady,
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    isLoading,
    currentPlan,
    currentDebt,
    fetchPlans,
    createPlan,
    updatePlan,
    deletePlan,
    createDebt,
    updateDebt,
    deleteDebt,
    createPlannedExtraDebtPayment,
    updatePlannedExtraDebtPayment,
    deletePlannedExtraDebtPayment,
    createDebtUpdate,
    updateDebtUpdate,
    deleteDebtUpdate,
    clearData,
  };
}
