import { DebtSnakeCase } from '@/types';
import { addDays, startOfDay, subMonths } from 'date-fns';

export function getInitialDebtUpdateValues(
  debtId: string,
  debtValues: Partial<DebtSnakeCase>
) {
  const startDate = debtValues.initial_next_payment_due_date
    ? addDays(
        subMonths(startOfDay(debtValues.initial_next_payment_due_date), 1),
        1
      ).toISOString()
    : null;
  const minimumPayment = debtValues.minimum_payment_fixed;
  const balance = debtValues.initial_balance;
  const apr = debtValues.apr;
  let interestCharged: number | null = null;
  if (balance && apr !== null && apr !== undefined) {
    interestCharged = (balance * (apr / 100)) / 12;
  }
  return {
    debtId,
    balance,
    startDate,
    endDate: debtValues.initial_next_payment_due_date,
    minimumPayment,
    interestCharged,
    payment: null,
  };
}
