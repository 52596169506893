import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    STRIPE_PRODUCT_ID: z.string(),
    STRIPE_SECRET_KEY: z.string(),
    STRIPE_IS_TEST_MODE: z
      .boolean()
      .transform(() => process.env.STRIPE_SECRET_KEY?.startsWith('sk_test_')),
    SUPABASE_SERVICE_ROLE_KEY: z.string().jwt(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_AMITY_API_KEY: z.string(),
    NEXT_PUBLIC_APP_URL: z.string().url(),
    NEXT_PUBLIC_DEV_MODE: z
      .string()
      .default('')
      .transform((val) => val === '1'),
    NEXT_PUBLIC_GLEAP_SDK_KEY: z.string(),
    NEXT_PUBLIC_POSTHOG_HOST: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string().jwt(),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_WEBSITE_URL: z.string().trim().url(),
    NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT: z
      .string()
      .trim()
      .optional()
      .transform((val) => Number(val) || null),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    NEXT_PUBLIC_AMITY_API_KEY: process.env.NEXT_PUBLIC_AMITY_API_KEY,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_DEV_MODE: process.env.NEXT_PUBLIC_DEV_MODE,
    NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT:
      process.env.NEXT_PUBLIC_FREE_TRIAL_DAYS_COUNT,
    NEXT_PUBLIC_GLEAP_SDK_KEY: process.env.NEXT_PUBLIC_GLEAP_SDK_KEY,
    NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_WEBSITE_URL: process.env.NEXT_PUBLIC_WEBSITE_URL,
    STRIPE_IS_TEST_MODE: process.env.STRIPE_SECRET_KEY?.startsWith('sk_test_'),
    STRIPE_PRODUCT_ID: process.env.STRIPE_PRODUCT_ID,
    STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
    SUPABASE_SERVICE_ROLE_KEY: process.env.SUPABASE_SERVICE_ROLE_KEY,
  },
});
