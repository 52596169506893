'use client';

import { createContext, useContext, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

export interface UseConfettiReturn {
  startConfetti: (duration?: number | null) => void;
  stopConfetti: () => void;
}

const ConfettiContext = createContext<UseConfettiReturn>({
  startConfetti: () => {},
  stopConfetti: () => {},
});

export const useConfetti = (): UseConfettiReturn => {
  return useContext(ConfettiContext);
};

export function ConfettiProvider({
  children,
}: {
  readonly children?: React.ReactNode;
}): JSX.Element {
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  const startConfetti = (duration?: number | null) => {
    setShowConfetti(true);
    if (duration) {
      setTimeout(() => setShowConfetti(false), duration);
    }
  };

  const stopConfetti = () => setShowConfetti(false);

  return (
    <ConfettiContext.Provider value={{ startConfetti, stopConfetti }}>
      {children}
      <Confetti
        width={width}
        height={height}
        numberOfPieces={showConfetti ? 500 : 0}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          pointerEvents: 'none',
        }}
      />
    </ConfettiContext.Provider>
  );
}
