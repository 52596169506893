import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DebtPayoffMethod, Plan } from '@/types';

interface AnonymousUserStore {
  plan: Plan;
  setPlan: (plan: Plan) => void;
  reset: () => void;
}

const INITIAL_PLAN: Plan = {
  id: 'anonymous-user-plan',
  name: 'Primary',
  debtPayoffMethod: DebtPayoffMethod.Avalanche,
  debtPayoffCustomOrder: [],
  debts: [],
  plannedExtraDebtPayments: [],
};

export const useAnonymousUserStore = create<AnonymousUserStore>()(
  persist(
    (set) => ({
      plan: INITIAL_PLAN,
      setPlan: (plan) => set({ plan }),
      reset: () => set({ plan: INITIAL_PLAN }),
    }),
    {
      name: 'finex/anonymous-user',
    }
  )
);
