import { DebtUpdate, Plan } from '@/types';

export function getDebtUpdateById(
  plan: Plan,
  id: string | null
): DebtUpdate | null {
  if (!id) return null;
  return (
    plan.debts
      .map((debt) => debt.updates ?? [])
      .flat()
      .find((du) => du.id === id) ?? null
  );
}
