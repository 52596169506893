import { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Button, Stack, Text, TextInput, Switch, Badge } from '@mantine/core';
import { modals } from '@mantine/modals';
import debounce from 'lodash.debounce';
import {
  UserRepository as ASCUserRepository,
  CommunityRepository as ASCCommunityRepository,
} from '@amityco/ts-sdk';
import { UseProfileReturn, UseUserReturn } from '@/hooks';
import { getInviteConfigById } from '@/utils';

const SetupProfileModal = ({ amityUser }: { amityUser: Amity.User }) => {
  const [newDisplayName, setNewDisplayName] = useState('');
  const [showDebtFreeCountdown, setShowDebtFreeCountdown] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const submit = async () => {
    if (isUpdating) return;
    setIsUpdating(true);

    console.log('⏳ Updating Amity user...', {
      newDisplayName,
      showDebtFreeCountdown,
    });
    const updatedUser = await ASCUserRepository.updateUser(amityUser.userId, {
      displayName: newDisplayName,
      metadata: {
        ...(amityUser.metadata ?? {}),
        showDebtFreeCountdown,
      },
    });
    console.log('✅ Successfully updated Amity user profile.', {
      newDisplayName,
      showDebtFreeCountdown,
      updatedUser,
    });

    modals.closeAll();
    setIsUpdating(false);
  };

  return (
    <Stack mb={-14} gap="md">
      <Text size="lg" fw={500}>
        Setup your profile ✨
      </Text>
      <TextInput
        label="Display name"
        placeholder="Display name..."
        c="gray.9"
        autoFocus
        value={newDisplayName}
        onChange={(e) => setNewDisplayName(e.currentTarget.value)}
        minLength={1}
        maxLength={100}
        size="md"
        disabled={isUpdating}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit();
          }
        }}
      />
      <Stack gap={2} mb={12} mt={8}>
        <Badge size="xs" variant="light" color="blue.9" ml={-2} pos="relative">
          Recommended
        </Badge>
        <Switch
          label="Show debt-free countdown"
          description="Helps you stay motivated on your debt-free journey with other community members."
          size="md"
          fw={500}
          c="gray.9"
          labelPosition="left"
          checked={showDebtFreeCountdown}
          onChange={(e) => setShowDebtFreeCountdown(e.currentTarget.checked)}
          styles={{
            description: {
              marginTop: 3,
              lineHeight: 1.3,
            },
          }}
        />
      </Stack>
      <Button
        fullWidth
        size="md"
        onClick={submit}
        disabled={!newDisplayName.trim()}
        loading={isUpdating}
      >
        Submit
      </Button>
    </Stack>
  );
};

export function useUpdateAmityUserProfile({
  user,
  profile,
  amityUser,
}: {
  readonly user: UseUserReturn;
  readonly profile: UseProfileReturn;
  readonly amityUser: Amity.User | null;
}): void {
  const isModalOpenRef = useRef(false);
  const pathname = usePathname();
  const inviteConfig = getInviteConfigById(user.data?.user_metadata.inviteId);

  const setIsModalOpen = (value: boolean) => {
    isModalOpenRef.current = value;
  };

  const checkIfNeedToUpdateAmityUserProfile = async (
    inputPathname: string,
    inputProfile: UseProfileReturn,
    inputAmityUser: Amity.User | null
  ) => {
    if (
      isModalOpenRef.current ||
      !inputPathname.startsWith('/community') ||
      !inputProfile.data ||
      !inputAmityUser
    ) {
      return;
    }

    if (
      inputAmityUser.displayName &&
      inputAmityUser.displayName !== inputProfile.data.id
    ) {
      console.log(
        '✅ useUpdateAmityUserProfile(), Amity displayName is valid:',
        inputAmityUser.displayName
      );
      return;
    }

    console.log(
      '⚠️ useUpdateAmityUserProfile(), Amity user displayName is not valid. Open modal...'
    );
    openSetupProfileModal(inputAmityUser);
  };

  const openSetupProfileModal = (inputAmityUser: Amity.User | null) => {
    if (!inputAmityUser) return;

    setIsModalOpen(true);
    modals.openConfirmModal({
      centered: true,
      closeOnEscape: false,
      closeOnClickOutside: false,
      withCloseButton: false,
      groupProps: {
        mb: 0,
      },
      confirmProps: {
        display: 'none',
      },
      cancelProps: {
        display: 'none',
      },
      children: <SetupProfileModal amityUser={inputAmityUser} />,
      onClose: async () => {
        setIsModalOpen(false);
        if (inviteConfig?.amityCommunityId) {
          await ASCCommunityRepository.joinCommunity(
            inviteConfig.amityCommunityId
          );
        }
      },
    });
  };

  const debouncedCheckIfNeedToUpdateAmityUserProfile = useCallback(
    debounce(checkIfNeedToUpdateAmityUserProfile, 3000, {
      leading: true,
    }),
    []
  );

  useEffect(() => {
    debouncedCheckIfNeedToUpdateAmityUserProfile(pathname, profile, amityUser);

    return () => {
      debouncedCheckIfNeedToUpdateAmityUserProfile.cancel();
    };
  }, [pathname, profile, amityUser]);
}
