import { addDays, addMonths, startOfDay, subMonths } from 'date-fns';
import { Debt, DebtUpdate, NextDebtUpdate } from '@/types';
import { getInterestCharged } from './getInterestCharged';

export function getNextDebtUpdate(
  debt?: Debt,
  latestDebtUpdate?: DebtUpdate
): NextDebtUpdate | undefined {
  if (
    !debt ||
    !latestDebtUpdate ||
    !debt.apr ||
    !latestDebtUpdate.endDate ||
    !latestDebtUpdate.balance ||
    !latestDebtUpdate.payment
  ) {
    return undefined;
  }

  const endDate = addMonths(startOfDay(latestDebtUpdate.endDate), 1);
  const startDate = addDays(subMonths(endDate, 1), 1);
  const balance = latestDebtUpdate.balance - latestDebtUpdate.payment;
  const interestCharged = getInterestCharged(balance, debt.apr);

  const now = new Date();
  const canShow = startDate <= now;

  if (canShow && balance > 0) {
    return {
      debtId: debt.id,
      startDate: startDate?.toISOString(),
      endDate: endDate.toISOString(),
      balance,
      interestCharged,
      minimumPayment: latestDebtUpdate.minimumPayment,
    };
  }
}
