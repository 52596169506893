import { Debt } from '@/types';
import { getSortedDebtUpdates } from './getSortedDebtUpdates';
import { getDebtInitialBalance } from './getDebtInitialBalance';

export function getLatestDebtStats(debt?: Debt | null): {
  initialBalance: number | null;
  currentBalance: number | null;
  lastMinimumPayment: number | null;
  lastPayment: number | null;
  nextDueDate: string | null;
  totalPayments: number | null;
} {
  const debtUpdates = getSortedDebtUpdates(debt?.updates);
  const balanceUpdate = debtUpdates.find((update) => update.balance !== null);
  const initialBalance = debt ? getDebtInitialBalance(debt) : null;

  let currentBalance: number | null = null;
  if (balanceUpdate?.balance) {
    currentBalance = balanceUpdate?.balance;
    if (balanceUpdate?.payment) {
      currentBalance -= balanceUpdate?.payment;
    }
  }

  const lastMinimumPayment =
    debtUpdates.find((update) => update.minimumPayment !== null)
      ?.minimumPayment ?? null;
  const lastPayment =
    debtUpdates.find((update) => update.payment !== null)?.payment ?? null;

  const lastUpdate = debtUpdates[0];
  const nextDueDate = lastUpdate?.endDate ?? null;

  const totalPayments = debtUpdates.reduce((acc, update) => {
    if (update.payment) {
      return acc + update.payment;
    }
    return acc;
  }, 0);

  return {
    initialBalance,
    currentBalance,
    lastMinimumPayment,
    lastPayment,
    nextDueDate,
    totalPayments,
  };
}
