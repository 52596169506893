import { DebtUpdate } from '@/types';

export function getSortedDebtUpdates(debtUpdates?: DebtUpdate[]): DebtUpdate[] {
  if (!debtUpdates) return [];
  return debtUpdates.sort((a, b) => {
    if (a.startDate && b.startDate) {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
    return 0;
  });
}
