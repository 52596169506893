import { Debt } from '@/types';

// Helper function to get effective APR for a debt at a given date
export const getEffectiveApr = (debt: Debt, date: Date): number => {
  if (
    debt.promoApr !== null &&
    debt.promoApr !== undefined &&
    debt.promoAprEndDate &&
    new Date(debt.promoAprEndDate) > date
  ) {
    return debt.promoApr;
  }
  return debt.apr || 0;
};
