import { useMemo } from 'react';
import { differenceInDays, startOfDay } from 'date-fns';
import { Debt, PlanSchedule, PlanScheduleItemType, PayoffStats } from '@/types';
import { getNonUnluckyNumber } from '@/utils';

export function usePayoffStats(
  planSchedule?: PlanSchedule | null,
  debt?: Debt | null
): PayoffStats {
  return useMemo(() => {
    if (!planSchedule) {
      return {
        payoffDate: null,
        payoffCountdown: null,
        totalInterest: null,
        totalPayments: 0,
        debtPayments: [],
      };
    }

    // Find the last DEBT_PAYOFF item in planSchedule
    const lastDebtPayoffItem = planSchedule
      .slice()
      .reverse()
      .find((item) => {
        if (debt) {
          return (
            item.debtId === debt.id &&
            item.type === PlanScheduleItemType.DebtPayoff
          );
        }
        return item.type === PlanScheduleItemType.DebtFree;
      });

    // Calculate payoff date
    let payoffCountdown: number | null = null;
    if (lastDebtPayoffItem?.date) {
      payoffCountdown = differenceInDays(
        lastDebtPayoffItem.date,
        startOfDay(new Date())
      );
      payoffCountdown = Math.max(0, getNonUnluckyNumber(payoffCountdown));
    }

    // Get debt payments for either the specific debt or all debts
    const debtPayments = planSchedule.filter((item) => {
      if (debt) {
        return (
          item.debtId === debt.id &&
          item.type === PlanScheduleItemType.DebtPayment
        );
      }
      return item.type === PlanScheduleItemType.DebtPayment;
    });

    // Get the total interest from the debt payments
    const totalInterest = debtPayments.reduce((total, item) => {
      return total + (item.interest ?? 0);
    }, 0);

    return {
      payoffDate: lastDebtPayoffItem?.date ?? null,
      payoffCountdown,
      totalInterest,
      totalPayments: debtPayments.length,
      debtPayments,
    };
  }, [planSchedule]);
}
