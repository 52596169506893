import { create } from "zustand";
import { persist } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import { Plan, Debt, DebtPayoffMethod } from "@/types";

interface PlansStore {
  plans: Plan[];
  addPlan: (name: string) => void;
  getPlan: (id: string) => Plan | undefined;
  updatePlan: (id: string, plan: Plan) => void;
  addDebtToPlan: (planId: string, debt: Omit<Debt, "id">) => void;
  updatePlanDebt: (planId: string, debt: Debt) => void;
  deletePlanDebt: (planId: string, debtId: string) => void;
}

export const usePlansStore = create<PlansStore>()(
  persist(
    (set, get) => ({
      plans: [],
      addPlan: (name: string) => {
        const newPlan: Plan = {
          id: uuidv4(),
          name,
          debts: [],
          debtPayoffMethod: DebtPayoffMethod.Avalanche,
          debtPayoffCustomOrder: [],
          plannedExtraDebtPayments: [],
        };
        set((state) => ({ plans: [...state.plans, newPlan] }));
      },
      getPlan: (id: string) => {
        const { plans } = get();
        return plans.find((plan) => plan.id === id);
      },
      updatePlan: (id: string, plan: Plan) => {
        set((state) => ({
          plans: state.plans.map((p) => (p.id === id ? plan : p)),
        }));
      },
      addDebtToPlan: (planId: string, debt: Omit<Debt, "id">) => {
        set((state) => ({
          plans: state.plans.map((plan) =>
            plan.id === planId
              ? {
                  ...plan,
                  debts: [...plan.debts, { ...debt, id: uuidv4() }],
                }
              : plan
          ),
        }));
      },
      updatePlanDebt: (planId: string, debt: Debt) => {
        set((state) => ({
          plans: state.plans.map((plan) =>
            plan.id === planId
              ? {
                  ...plan,
                  debts: plan.debts.map((d) => (d.id === debt.id ? debt : d)),
                }
              : plan
          ),
        }));
      },
      deletePlanDebt: (planId: string, debtId: string) => {
        set((state) => ({
          plans: state.plans.map((plan) =>
            plan.id === planId
              ? {
                  ...plan,
                  debts: plan.debts.filter((d) => d.id !== debtId),
                }
              : plan
          ),
        }));
      },
    }),
    {
      name: "finex/plans",
    }
  )
);
