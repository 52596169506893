/**
 * Helper function to round to 4 decimal places
 * This ensures consistent values throughout calculations
 *
 * @param value The value to round
 * @returns The rounded value
 */
export function round(value: number | null, decimalPlaces = 4): number | null {
  if (value === null) return value;
  const factor = 10 ** decimalPlaces;
  return Math.round(value * factor) / factor;
}
