import { Debt, DebtUpdate } from '@/types';

// Helper function to find the latest debt update before or on a given date
export const findLatestDebtUpdate = (
  debt: Debt,
  date: Date
): DebtUpdate | null => {
  if (!debt.updates || debt.updates.length === 0) return null;

  // Sort updates by start date descending
  const sortedUpdates = [...debt.updates].sort((a, b) => {
    const dateA = a.startDate ? new Date(a.startDate) : new Date(0);
    const dateB = b.startDate ? new Date(b.startDate) : new Date(0);
    return dateB.getTime() - dateA.getTime();
  });

  // Find the latest update that is before or on the given date
  return (
    sortedUpdates.find((update) => {
      if (!update.startDate) return false;
      const updateDate = new Date(update.startDate);
      return updateDate <= date;
    }) || null
  );
};
