import { useCallback, useEffect, useRef } from 'react';
import { UserRepository as ASCUserRepository } from '@amityco/ts-sdk';
import debounce from 'lodash.debounce';
import { UseProfileReturn } from '@/hooks';

export function useUpdateAmityUserDebtFreeDate({
  profile,
  amityUser,
}: {
  readonly profile: UseProfileReturn;
  readonly amityUser: Amity.User | null;
}): void {
  const isUpdatingRef = useRef(false);

  const setIsUpdating = (value: boolean) => {
    isUpdatingRef.current = value;
  };

  const updateAmityUserDebtFreeDate = async (
    inputProfile: UseProfileReturn,
    inputAmityUser: Amity.User | null
  ) => {
    if (isUpdatingRef.current || !inputProfile.data || !inputAmityUser) {
      return;
    }

    if (
      inputAmityUser.metadata?.debtFreeDate === inputProfile.data.debtFreeDate
    ) {
      console.log('✅ Amity user has the same debtFreeDate as in profile.');
      return;
    }

    setIsUpdating(true);
    console.log('⚠️ Amity user metadata does not match profile. Updating...', {
      debtFreeDate: inputProfile.data.debtFreeDate,
    });
    await ASCUserRepository.updateUser(inputProfile.data.id, {
      metadata: {
        ...(inputAmityUser.metadata ?? {}),
        debtFreeDate: inputProfile.data.debtFreeDate,
      },
    });
    setTimeout(() => setIsUpdating(false), 1000);
  };

  const debouncedUpdateAmityUserDebtFreeDate = useCallback(
    debounce(updateAmityUserDebtFreeDate, 3000),
    []
  );

  useEffect(() => {
    debouncedUpdateAmityUserDebtFreeDate(profile, amityUser); // DEV

    return () => {
      setIsUpdating(false);
      debouncedUpdateAmityUserDebtFreeDate.cancel();
    };
  }, [profile, amityUser]);
}
